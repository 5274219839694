import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { DxDashboardControlComponent } from 'devexpress-dashboard-angular/ui/dashboard-control';
import { AppComponent } from 'app/app.component';
import { IconDefinition, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard-component',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  dashBoardId: string;
  refreshInterval: number;
  viewerMode: boolean = false;
  subscription: Subscription;
  userId: string;
  loadedFromDirect: boolean = false;
  @ViewChild(DxDashboardControlComponent, { static: false }) dashboardControl: DxDashboardControlComponent;
  faArrowLeftLong: IconDefinition = faArrowLeftLong;

  constructor(public app: AppComponent) { }

  ngOnInit() {
    this.userId = this.app.cookieService.get('loggedIn').split('|')[1];
    if (sessionStorage.getItem('dashboardId') != undefined) {
      this.dashBoardId = sessionStorage.getItem('dashboardId').toString().split('|')[0];
      this.refreshInterval = Number(sessionStorage.getItem('dashboardId').toString().split('|')[1]);
      sessionStorage.removeItem('dashboardId');
      this.viewerMode = true;

      this.subscription = interval(this.refreshInterval * 1000).subscribe(() => {
        this.dashboardControl.instance.refresh();
      });

      return;
    }

    if (sessionStorage.getItem('directedDashboardId') != undefined) {
      this.dashBoardId = sessionStorage.getItem('directedDashboardId').toString();
      sessionStorage.removeItem('directedDashboardId');
      this.viewerMode = true;
      this.loadedFromDirect = true;
      this.subscription = interval(60000).subscribe(() => {
        this.dashboardControl.instance.refresh();
      });

      return;
    }
  }

  back(): void {
    sessionStorage.clear();
    sessionStorage.setItem('userId', this.userId);
    window.location.href = '/base';
  }
}
