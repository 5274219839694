<p-dialog [visible]="true" [breakpoints]="{'440px': '100%'}" [style]="{width: '90%'}" [baseZIndex]="10000"
  [ngClass]="{'change-scrollbar-color-darkMode': darkMode, 'change-scrollbar-color': !darkMode}" [modal]="true"
  [maximizable]="false" [draggable]="false" [resizable]="false">
  <div class="row sticky dialog-title-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 unset-p">
      <div class="flex relative align-centre dialog-title">
        <span class="textGreen font16 b-radius dialog-title-icon"
          [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
          <fa-icon [icon]="faPen"></fa-icon>          
        </span>
        <span class="font17" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{action.caption}}</span>
        <div class="absolute" style="right: 15px;">
          <span class="textGreen font16 b-radius dialog-title-icon cursorPointer" (click)="close()"
            [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
            <fa-icon [icon]="faXmark"></fa-icon>            
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 relative">      
      <ng-template [ngIf]="!action.prePopulate">
        <button class="btn font17" (click)="addRow()">
          <fa-icon [icon]="faPlus" class="textGreen"></fa-icon>          
          <span class="btnControlText" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
            {{app.translations.GRID_btn_Add}}
          </span>
        </button>             
        <button class="btn font17" (click)="pasteExcelData()">
          <fa-icon [icon]="faPaste" class="textGreen"></fa-icon>         
          <span class="btnControlText" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
            {{app.translations.GBL_btn_PasteData}}
          </span>
        </button>         
      </ng-template>
      <ng-template [ngIf]="compiledLines.length > 0">
        <span class="font15 float-right" style="margin: 9px 0px 0px;" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
          {{app.translations.GRID_lbl_EditLineCount}} : <strong>{{gridRows.length}}</strong>
        </span>
      </ng-template>
    </div>
  </div>
  <div class="row" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'" style="padding: 10px 0px !important;">
    <div class="col-12">
      <!--check if this is still needed-->     
      <ng-template [ngIf]="gridRows.length == 0 && compiledLines.length > 0">
        <div class="text-center">
          <span class="font30" [ngClass]="darkMode ? 'textWhite' : 'textBlue'">
            <fa-icon [icon]="faCircleNotch" class="textGreen"></fa-icon>      
          </span>
        </div>
      </ng-template>
      <ng-template [ngIf]="gridRows.length > 0">
        <table class="full-width">
          <thead>
            <tr>
              <th class="padding5" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'"></th>
              <ng-container *ngFor="let param of parameters">
                <th class="padding5" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
                  <span class="font14" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{param.caption}}</span>
                </th>
              </ng-container>
              <ng-template [ngIf]="isSavingGrid">
                <th class="animated fadeIn padding5" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'"></th>
              </ng-template>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of gridRows">
              <tr class="animated fadeIn">
                <td class="padding5" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
                  <fa-icon [icon]="faTrash" class="font17 cursorPointer padding5 b-radius" (click)="removeRow(item)"
                  [ngClass]="darkMode ? 'bg-dark-secondary textWhite' : 'bg-light-blue textGreen'"></fa-icon>                  
                </td>
                <ng-container *ngFor="let param of item.params">
                  <td class="padding5" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
                    <ng-template [ngIf]="param.type == 'combolookup'">
                      <select #ddl class="font14 cursorPointer b-radius full-width form-select"
                        [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" (change)="updateCombos(param, item, $event)">
                        <option class="cursorPointer textBlack" [value]="0">{{app.translations.GBL_lbl_SelectOption}}
                        </option>
                        <ng-container *ngFor="let val of param.lookupValues">
                          <ng-template [ngIf]="val.id == '-1'">
                            <option class="cursorPointer textBlack" [value]="val.value"
                              [selected]="val.value == param.preselectedValue">
                              {{val.value}}
                            </option>
                          </ng-template>
                          <ng-template [ngIf]="val.id > 0">
                            <option class="cursorPointer textBlack" [value]="val.id"
                              [selected]="val.value == param.preselectedValue">
                              {{val.value}}
                            </option>
                          </ng-template>
                        </ng-container>
                      </select>
                    </ng-template>
                    <ng-template [ngIf]="param.type == 'combo' || param.type == 'combobox'">
                      <select #ddl class="font14 cursorPointer b-radius full-width form-select"
                        (change)="updateCombos(param, item, $event)" [ngClass]="[darkMode ? 'textWhite' : 'textBlack']">
                        <option class="cursorPointer textBlack" [value]="0">{{app.translations.GBL_lbl_SelectOption}}</option>
                        <ng-container *ngFor="let val of param.lookupValues">
                          <option class="cursorPointer textBlack" [value]="val"
                            [selected]="val == param.preselectedValue">
                            {{val}}
                          </option>
                        </ng-container>
                      </select>
                    </ng-template>
                    <ng-template
                      [ngIf]="(param.type == 'text' || param.type == 'textbox') && (param.dataType == 'Int32')">
                      <input type="number" class="form-control font13" step="1" pattern="[0-9]*"
                        (change)="updateNumericInputs(param, $event)"
                        [value]="compiledLines.length > 0 ? param.preselectedValue : ''"
                        [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" />
                    </ng-template>
                    <ng-template
                      [ngIf]="(param.type == 'text' || param.type == 'textbox') && (param.dataType == 'Decimal' || param.dataType == 'Double')">
                      <input type="number" class="form-control font13" (change)="updateNumericInputs(param, $event)"
                        [value]="compiledLines.length > 0 ? param.preselectedValue : ''"
                        [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" />
                    </ng-template>
                    <ng-template
                      [ngIf]="(param.type == 'datetimebox') && (param.dataType == '' || param.dataType == 'DateTime')">
                      <input type="datetime-local" class="form-control font13"
                        (change)="updateTextInputs(param, $event)"
                        [value]="compiledLines.length > 0 ? param.preselectedValue : ''"
                        [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" />
                    </ng-template>
                    <ng-template
                      [ngIf]="(param.type == 'date' || param.type == 'datebox') && (param.dataType == '' || param.dataType == 'DateTime')">
                      <input type="date" class="form-control font13" (change)="updateTextInputs(param, $event)"
                        [value]="compiledLines.length > 0 ? param.preselectedValue : ''"
                        [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" />
                    </ng-template>
                    <ng-template
                      [ngIf]="(param.type == 'time' || param.type == 'timebox') && (param.dataType == '' || param.dataType == 'DateTime' || param.dataType == 'TimeSpan')">
                      <input type="time" class="form-control font13" (change)="updateTextInputs(param, $event)"
                        [value]="compiledLines.length > 0 ? param.preselectedValue : ''"
                        [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" />
                    </ng-template>
                    <ng-template
                      [ngIf]="(param.type == 'text' || param.type == 'textbox') && (param.dataType == '' || param.dataType == 'String')">
                      <input type="text" class="font13 form-control" (change)="updateTextInputs(param, $event)"
                        [value]="(compiledLines.length > 0 || pastedDataFromExcel) ? param.preselectedValue : ''"
                        [ngClass]="darkMode ? 'textWhite' : 'textBlack'" />
                    </ng-template>
                    <ng-template [ngIf]="param.type == 'textarea'">
                      <div class="relative">
                        <textarea class="form-control font13 pre-wrap-white-space transition5"
                          (blur)="loadLookupsFromTextArea(param, item)" [(ngModel)]="param.preselectedValue"
                          (change)="updateTextInputs(param, $event)" rows="2"
                          style="padding: 5px 25px 5px 5px !important;" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"
                          [ngStyle]="{'border-color': item.sqlError.length > 0 ? 'var(--red)' : '#ced4da'}"></textarea>
                        <ng-template [ngIf]="item.sqlError.length > 0">
                          <fa-icon [icon]="faCircleInfo" class="absolute font14 textRed cursorPointer"
                          style="top: 7px; right: 10px;" pTooltip="{{item.sqlError}}" tooltipPosition="top"></fa-icon>                          
                        </ng-template>
                      </div>
                    </ng-template>
                    <ng-template [ngIf]="param.type == 'password'">
                      <input type="password" autocomplete="new-password"
                        [value]="compiledLines.length > 0 ? param.preselectedValue : ''"
                        [ngClass]="darkMode ? 'textWhite' : 'textBlack'" (change)="updateTextInputs(param, $event)"
                        class="form-control font13" />
                    </ng-template>
                    <ng-template [ngIf]="param.type == 'check' || param.type == 'toggle' || param.type == 'checkbox'">
                      <mat-checkbox (change)="updateCheckboxToggles(param, $event)" [checked]="param.preselectedValue">
                      </mat-checkbox>
                    </ng-template>
                    <ng-template [ngIf]="param.type == 'image' || param.type == 'imagebox'">
                      <label (click)="fileInput.value = ''" tooltipPosition="top"
                        pTooltip="{{app.translations.GBL_hint_UploadImage}}">
                        <fa-icon [icon]="faArrowUpFromBracket" class="font17 cursorPointer b-radius padding5"
                        [ngClass]="darkMode ? 'bg-dark-secondary textWhite' : 'bg-light-blue textGreen'"></fa-icon>                       
                        <input #fileInput type="file" class="display-none" (change)="updateImage(param, $event)">
                      </label>
                      <ng-template [ngIf]="param.preselectedValue">
                        <img [src]="param.preselectedValue" (dblclick)="removeImage(param);"
                          [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-whtie'"
                          style="width: 30px; margin: 10px;" class="cursorPointer b-radius padding2" />
                      </ng-template>
                    </ng-template>
                  </td>
                </ng-container>
                <ng-template [ngIf]="isSavingGrid">
                  <ng-template [ngIf]="item.saveResult == 'saving'">
                    <td class="padding5" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
                      <div class="animated fadeIn">
                        <fa-icon [icon]="faCircleNotch" class="font17 animated rotate"
                        [ngClass]="darkMode ? 'textWhite' : 'textGreen'"></fa-icon>                        
                      </div>
                    </td>
                  </ng-template>
                  <ng-template [ngIf]="item.saveResult == 'success'">
                    <td class="padding5" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
                      <div class="animated fadeIn">
                        <fa-icon [icon]="faCheck" class="textGreen font17"></fa-icon>                       
                        <span class="font14" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"
                          style="padding-left: 8px;">{{item.message}}</span>
                      </div>
                    </td>
                  </ng-template>
                  <ng-template [ngIf]="item.saveResult == 'error'">
                    <td class="padding5" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
                      <div class="animated fadeIn">
                        <fa-icon [icon]="faXmark" class="textRed font17"></fa-icon>                        
                        <span class="font14" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"
                          style="padding-left: 8px;">{{item.message}}</span>
                      </div>
                    </td>
                  </ng-template>
                </ng-template>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-template>
    </div>
  </div>
  <div class="row sticky dialog-footer-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 unset-p">
      <div class="flex justify-right padding10 dialog-footer">
        <button class="btn font17" (click)="save()">
          <fa-icon [icon]="faFloppyDisk" class="textGreen"></fa-icon>         
          <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
            {{app.translations.GBL_btn_SaveText}}
          </span>
        </button>
      </div>
    </div>
  </div>
</p-dialog>