<ng-template [ngIf]="!viewerMode">
  <dx-dashboard-control #dashboardControl class="full-width block full-vh-height" endpoint="/dashboard">
    <dxo-extensions>
      <dxo-data-source-wizard [enableCustomSql]="true"></dxo-data-source-wizard>
    </dxo-extensions>
  </dx-dashboard-control>
</ng-template>
<ng-template [ngIf]="viewerMode"> 
  <ng-template [ngIf]="loadedFromDirect">
    <button class="btn absolute btn-back" (click)="back()">
      <fa-icon [icon]="faArrowLeftLong"></fa-icon>      
    </button>
  </ng-template>
  <dx-dashboard-control #dashboardControl class="full-width block full-vh-height" endpoint="/dashboard"
    dashboardId="{{dashBoardId}}" workingMode='ViewerOnly'>
  </dx-dashboard-control>
</ng-template>