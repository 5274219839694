<p-dialog [visible]="true" [breakpoints]="{'440px': '100%'}" [style]="{width: '40%'}" [baseZIndex]="10000" [modal]="true" [maximizable]="false"
  [draggable]="false" [resizable]="false">
  <div class="row sticky dialog-title-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 unset-p">
      <div class="flex relative align-centre dialog-title">
        <span class="textGreen font16 b-radius dialog-title-icon"
          [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
          <fa-icon [icon]="faArrowUpFromBracket"></fa-icon>          
        </span>
        <span class="font17" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
          {{app.translations.IMP_lbl_Title}}
        </span>
        <div class="absolute" style="right: 15px;">
          <span class="textGreen font16 b-radius dialog-title-icon cursorPointer" (click)="close()"
            [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
            <fa-icon [icon]="faXmark"></fa-icon>            
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row " [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="flex padding10">
      <div class="col-3 flex unset-p flex align-centre">
        <select #ddl class="font14 unset-b padding5 b-radius unset-outline cursorPointer form-select"
          [ngClass]="darkMode ? 'bg-dark-secondary textWhite' : 'bg-white textBlack'" (change)="selectModule($event)">
          <option class="cursorPointer textBlack" [value]="0">{{app.translations.GBL_lbl_SelectOption}}</option>
          <ng-container *ngFor="let module of modules">
            <option class="cursorPointer textBlack" [value]="module.value">
              {{module.value}}
            </option>
          </ng-container>
        </select>
      </div>
      <div class="col-9 flex padding5 flex align-centre">
        <label class="" (click)="fileInput.value = ''" pTooltip="{{app.translations.GBL_hint_UploadFile}}"
          tooltipPosition="top">
          <fa-icon [icon]="faArrowUpFromBracket" class="font20 cursorPointer b-radius padding10"
          [ngClass]="darkMode ? 'bg-dark-secondary textWhite' : 'bg-light-blue textGreen'"></fa-icon>         
          <input #fileInput type="file" class="display-none" (change)="uploadFile($event)">
        </label>
      </div>       
    </div>   
    <ng-template [ngIf]="uploadedFileName">
      <div class="col-12 unset-p" style="margin: 10px 0px;">
        <span class="font15 padding10" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
          {{uploadedFileName}}
        </span>
      </div>       
    </ng-template>     
  </div>
  <div class="row sticky dialog-footer-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 unset-p">
      <div class="flex justify-right padding10 dialog-footer">
        <button class="btn font17" (click)="importFile()">
          <fa-icon [icon]="faCheckDouble" class="textGreen"></fa-icon>          
          <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
            {{app.translations.GBL_btn_ConfirmText}}
          </span>
        </button>
      </div>
    </div>
  </div>
</p-dialog>