import { Component, OnInit, Input, HostListener } from '@angular/core';
import { IParameter } from 'app/_models/IParameter';
import { IGridActionRow } from '../_models/IGridActionRow';
import { AppComponent } from 'app/app.component';
import { ICompiledLine } from 'app/_models/ICompiledLine';
import { CommunicationService } from 'app/_services/communication.service';
import { IAction } from 'app/_models/IAction';
import { RADController } from 'app/_controllers/radController';
import { IHeader } from 'app/_models/IHeader';
import * as XLSX from 'xlsx';
import { 
  IconDefinition, faPen, faXmark, faPlus, faPaste, faCircleNotch, faTrash, 
  faCircleInfo, faArrowUpFromBracket, faCheck, faFloppyDisk 
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'grid-action-component',
  templateUrl: './grid-action.component.html',
  styleUrls: ['./grid-action.component.css']
})
export class GridActionComponent implements OnInit {
  //#region Fields
  @Input() headers: IHeader[] = [];
  @Input() compiledLines: ICompiledLine[] = [];
  @Input() nodeId: string;
  @Input() userId: string;
  @Input() username: string;
  @Input() darkMode: boolean;
  @Input() action: IAction;
  prePopulate: boolean = true;
  parameters: IParameter[] = [];
  gridRows: IGridActionRow[] = [];
  gridRowToUpdate: IGridActionRow;
  isSavingGrid: boolean = false;
  sourceViewHeadersUsed: boolean = false;
  j: number = 0;
  invalidValues: boolean = false;
  isRowsSaved: boolean = false;
  pastedDataFromExcel: boolean = false;
  //#endregion
  //#region Icons
  faPen: IconDefinition = faPen;
  faXmark: IconDefinition = faXmark;
  faPlus: IconDefinition = faPlus;
  faPaste: IconDefinition = faPaste;
  faCircleNotch: IconDefinition = faCircleNotch;
  faTrash: IconDefinition = faTrash;
  faCircleInfo: IconDefinition = faCircleInfo;
  faArrowUpFromBracket: IconDefinition = faArrowUpFromBracket;
  faCheck: IconDefinition = faCheck;
  faFloppyDisk: IconDefinition = faFloppyDisk;
  //#endregion

  constructor(
    public app: AppComponent,
    private communicationService: CommunicationService,
    private radController: RADController
  ) { }  

  ngOnInit(): void {
    this.parameters = this.action.parameters;
    this.prePopulate = this.action.prePopulate;
    this.j = 0;
    this.loadSourceViewHeaders();
  }

  loadSourceViewHeaders(): void {
    let param = this.parameters[this.j];
    if (param != undefined && param.sourceView && this.j < this.parameters.length) {
      this.app.showLoader();
      this.radController.getSourceViewHeaders(this.nodeId, this.action.name, param.sourceView).then(result => {
        param.sourceViewHeaders = result;
        this.sourceViewHeadersUsed = true;
        this.j++;
        this.loadSourceViewHeaders();
      })
    } else if (this.j == this.parameters.length) {
      this.app.hideLoader();
      this.initialise();
    } else {
      this.j++;
      this.loadSourceViewHeaders();
    }
  }

  initialise(): void {
    let childParams = this.parameters.filter(param => param.filterParameter != null);

    this.parameters.forEach(param => {
      param.isParentLookup = childParams.some(childParam => param.caption === childParam.filterParameter);
      param.preselectedValue = '';
      if (param.type == 'combolookup' && param.data.includes('@USERID')) {
        param.data = param.data.replace('@USERID', this.userId);
      }

      if (param.type == 'combo' || param.type == 'combobox') {
        param.lookupValues = param.data.split(',');
      }

      param.dataType = '';
      let headersToCheck = this.sourceViewHeadersUsed ? param.sourceViewHeaders : this.headers;
      headersToCheck.forEach(header => {
        if (param.caption === header.caption) {
          param.dataType = header.dataType;
        }
      });

      if (!param.dataType) {
        if (param.type.includes('date')) param.dataType = 'DateTime';
        if (param.type.includes('text')) param.dataType = 'String';
      }
    });

    for (var i = 0; i < this.compiledLines.length; i++) {
      for (let key in this.compiledLines[i].lineData) {
        for (var j = 0; j < this.parameters.length; j++) {
          let parameter = this.parameters[j];
          if (key == parameter.caption || (parameter.sourceColumn && parameter.sourceColumn == key)) {
            parameter.preselectedValue = this.compiledLines[i].lineData[key]?.toString();
            parameter.value = this.compiledLines[i].lineData[key]?.toString();

            if (parameter.preselectedValue == undefined) parameter.preselectedValue = '';
            if (parameter.value == undefined) parameter.value = '';

            if (parameter.type == 'image' || parameter.type == 'imagebox') {
              parameter.preselectedValue = parameter.preselectedValue.toString().replace('{\"changingThisBreaksApplicationSecurity\":', '');
              parameter.preselectedValue = parameter.preselectedValue.toString().replace('}', '');
              parameter.preselectedValue = parameter.preselectedValue.toString().replace('SafeValue must use [property]=binding: ', '');
              parameter.preselectedValue = parameter.preselectedValue.toString().replace(' (see https://g.co/ng/security#xss)', '');
              parameter.value = parameter.value.toString().replace('{\"changingThisBreaksApplicationSecurity\":', '');
              parameter.value = parameter.value.toString().replace('}', '');
              parameter.value = parameter.value.toString().replace('SafeValue must use [property]=binding: ', '');
              parameter.value = parameter.value.toString().replace(' (see https://g.co/ng/security#xss)', '');
            }

            if (parameter.isParentLookup) {
              let childParams = this.parameters.filter(param => param.filterParameter != null && param.filterParameter == parameter.caption);
              childParams.forEach(childParam => { childParam.filterValue = parameter.preselectedValue; })
            }
          }
        }
      }

      this.gridRows.push({
        id: this.gridRows.length + 1,
        params: JSON.parse(JSON.stringify(this.parameters)),
        saveResult: 'saving',
        message: '',
        sqlError: ''
      });
    }

    if (this.gridRows.length > 0) {
      this.j = 0;
      this.initialiseLookupsByRow();
      return;
    }

    this.j = 0;
    this.loadLookups(false, this.parameters, undefined);
  }

  initialiseLookupsByRow(): void {
    if (this.j < this.gridRows.length) {
      let params = this.gridRows[this.j].params;
      params.forEach(param => {
        if (param.type == 'combolookup' && param.lookupValues.length == 0) {
          param.lookupValues = [];
          let id = this.compiledLines.length > 0 ? this.compiledLines[0].id : '';          
          this.radController.getLookupData(param.data, param.filterValue, id).then(result => {
            if (result != undefined && result.length > 0 && result[0].id.includes('ERROR')) {
              if (result[0].value.includes('temp')) {
                this.radController.getLookupData(param.data, param.filterValue, id).then(result => {
                  param.lookupValues = result;
                });
              }
            } else {
              param.lookupValues = result;
            }
          });
        }
      })
      this.j++;
      this.initialiseLookupsByRow();
    } else if (this.j == this.gridRows.length) {
      return;
    } else {
      this.j++;
      this.initialiseLookupsByRow();
    }
  }

  loadLookups(fromTextArea: boolean, params: IParameter[], row: IGridActionRow): void {
    if (this.j < params.length) {
      let param = params[this.j];
      if (param.type == 'combolookup') {
        param.lookupValues = [];
        let id = this.compiledLines.length > 0 ? this.compiledLines[0].id : '';
        this.radController.getLookupData(param.data, param.filterValue, id).then(result => {
          if (row) row.sqlError = '';
          if (result != undefined && result.length > 0 && result[0].id.includes('ERROR')) {
            if (result[0].value.includes('temp')) {
              this.radController.getLookupData(param.data, param.filterValue, id).then(result => {
                param.lookupValues = result;
              });
            } else if (fromTextArea) {
              if (row) row.sqlError = result[0].value;
            }
          } else {
            param.lookupValues = result;
          }
        });
      }

      if (param.type == 'combo' || param.type == 'combobox') {
        param.lookupValues = param.data.split(',');
      }

      this.j++;
      this.loadLookups(fromTextArea, params, row);
    } else if (this.j == params.length) {
      return;
    } else {
      this.j++;
      this.loadLookups(fromTextArea, params, row);
    }
  }

  addRow(): void {    
    this.gridRows.push({
      id: this.gridRows.length + 1,
      params: JSON.parse(JSON.stringify(this.parameters)),
      saveResult: 'saving',
      message: '',
      sqlError: ''
    });

    this.j = 0;
    this.initialiseLookupsByRow();
  }

  async pasteExcelData(): Promise<void> {    
    let clipboardText = await navigator.clipboard.readText();
    if (clipboardText && clipboardText.includes('\n') && clipboardText.includes('\t')) {
      let rows = clipboardText.trim().split('\n');
      if (rows.length > 0) {
        let workbook = XLSX.read(clipboardText, { type: 'string' });
        let worksheet = workbook.Sheets[workbook.SheetNames[0]];
        let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        let formattedData = jsonData.map((row: any[]) => {
          return row.reduce((acc, cell, i) => {
            acc[`Column${i + 1}`] = cell;
            return acc;
          }, {});
        });

        this.pastedDataFromExcel = true;
        formattedData.forEach(line => {
          let parametersFromPaste = JSON.parse(JSON.stringify(this.parameters));
          Object.keys(line).forEach((key, i) => {
            let param = parametersFromPaste[i];
            param.preselectedValue = line[key];
            param.value = line[key];
            if (param.isParentLookup) {
              let childParams = parametersFromPaste.filter(parameter => parameter.filterParameter == param.caption);              
              childParams.forEach(childParam => { childParam.filterValue = param.preselectedValue; })
            }
          })

          this.gridRows.push({
            id: this.gridRows.length + 1,
            params: parametersFromPaste,
            saveResult: 'saving',
            message: '',
            sqlError: ''
          });
        })

        this.j = 0;
        this.initialiseLookupsByRow();
        return;
      }
    } else {
      this.app.alertError(this.app.translations.ALERT_Error_InvalidExcelData);
    }    
  }

  updateCheckboxToggles(parameter: IParameter, event: any): void {
    parameter.value = event.checked;
    parameter.preselectedValue = event.checked;
  }

  updateNumericInputs(parameter: IParameter, event: any): void {
    if (parameter.dataType == 'Int32') {
      let value = event.srcElement.value.toString();
      if (value.includes(',') || value.includes('.')) {
        this.app.alertError(`${this.app.translations.ALERT_Error_InvalidDecimal} ${parameter.caption}`);
        this.invalidValues = true;
        return;
      }
    }

    this.invalidValues = false;
    parameter.value = event.srcElement.value.toString();
    parameter.preselectedValue = event.srcElement.value.toString();
  }

  updateCombos(parameter: IParameter, row: IGridActionRow, event: any): void {
    parameter.value = '';
    parameter.preselectedValue = '';
    if (event.srcElement.value.toString() != '0') {
      parameter.value = event.srcElement.value.toString();
      parameter.preselectedValue = event.srcElement.value.toString();
    }

    if (!parameter.isParentLookup) return;
    let childParams = row.params.filter(param => param.filterParameter != null && param.filterParameter == parameter.caption);
    childParams.forEach(childParam => { childParam.filterValue = parameter.value; })

    this.j = 0;
    this.loadLookups(false, row.params, row);
  }

  updateTextInputs(parameter: IParameter, event: any): void {
    parameter.value = event.srcElement.value.toString();
    parameter.preselectedValue = event.srcElement.value.toString();
  }

  updateImage(parameter: IParameter, event: any): void {
    let fileToUpload = event.target.files;
    let file = fileToUpload[0] as File;

    if (!file.name.includes('.png') && !file.name.includes('.jpg')) {
      this.app.alertError(this.app.translations.ALERT_Error_InvalidImage);
      return;
    }
    
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64String = reader.result as string;
      parameter.value = base64String;      
      parameter.preselectedValue = base64String;
    };
  }

  loadLookupsFromTextArea(parameter: IParameter, row: IGridActionRow): void {
    if (!parameter.isParentLookup) return;

    let childParams = row.params.filter(param => param.filterParameter != null && param.filterParameter == parameter.caption);
    childParams.forEach(childParam => { childParam.filterValue = parameter.value; })

    this.j = 0;
    this.loadLookups(true, row.params, row);
  }

  saveRows(): void {
    if (this.j == this.gridRows.length) {
      this.isRowsSaved = true;
      return;
    }

    if (this.j < this.gridRows.length) {
      let gridRow = this.gridRows[this.j];
      if (gridRow.saveResult == 'error') {
        gridRow.saveResult = 'saving'
      }
      gridRow.sqlError = '';
      if (gridRow.saveResult != 'success') {
        let id;
        if (this.compiledLines.length == 1) id = this.compiledLines[0].id;
        else id = this.compiledLines.length > 0 ? this.compiledLines[this.j].id : '';

        this.radController.createUpdateSingleRecord(this.nodeId, this.userId, this.action.name, id, gridRow.params).then(result => {
          if (result == 'noresult' || !result.includes('ERROR')) {
            gridRow.saveResult = 'success';

            if (result == 'noresult' || result == '') gridRow.message = this.app.translations.GRID_lbl_ExecuteSuccess;
            else gridRow.message = result;
          }

          if (result.includes('ERROR') || result.includes('ERORR')) {
            gridRow.saveResult = 'error';
            gridRow.message = result;
          }

          if(result == 'Invalid image') {
            gridRow.saveResult = 'error';
            gridRow.message = this.app.translations.ALERT_Error_ImageCannotProcess;
          }

          this.j++;
          this.saveRows();
        });
      } else {
        this.j++;
        this.saveRows();
      }
    }
  }

  save(): void {
    if (this.gridRows.length == 0) {
      this.app.alertInfo(this.app.translations.ALERT_Info_NothingToSave);
      return;
    }

    this.isSavingGrid = true;
    this.j = 0;
    this.saveRows();
  }

  removeImage(param: IParameter): void {
    param.value = '';
    param.preselectedValue = '';
  }

  removeRow(gridRow: IGridActionRow): void {
    if (this.gridRows.length == 1) {
      this.app.alertError(this.app.translations.ALERT_Error_RemoveAllRows);
      return;
    }

    this.gridRows.forEach((row, i) => {
      if (row.id == gridRow.id) this.gridRows.splice(i, 1);
    })

    //readjust row ids to be consecutive
    this.gridRows.forEach((row, i) => { row.id = i + 1; })
    if (this.gridRows.length == 0) this.isSavingGrid = false;
  }

  close(): void {
    this.parameters = [];
    this.communicationService.closeDialog();
    if (this.gridRows.length > 0 && this.isRowsSaved) this.communicationService.refreshAll();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Esc') this.close();
  }
}
