import { Component, Input, OnInit } from '@angular/core';
import { INavigationItem } from 'app/_models/INavigationItem';
import { CommunicationService } from 'app/_services/communication.service';
import { MenuService } from 'app/_services/menu.service';
import { AppComponent } from 'app/app.component';
import { IconDefinition, faCircleNotch, faChevronDown,faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {  
  @Input() item: INavigationItem;
  @Input() isMenuExpanded: boolean;
  @Input() globalFontSize: number;
  @Input() menuItemPadding: number;  
  @Input() userId: string;
  @Input() darkMode: boolean;
  items: INavigationItem[] = [];
  faCircleNotch: IconDefinition = faCircleNotch;
  faChevronDown: IconDefinition = faChevronDown;
  faChevronRight: IconDefinition = faChevronRight;

  constructor(
    private app: AppComponent,
    private communicationService: CommunicationService,
    private menuService: MenuService
  ) { }

  ngOnInit() {
    let id = this.item == undefined ? -1 : this.item.id;
    this.getMenu(Number(this.userId), id).then(menuItems => {
      this.items = menuItems;
    });
  }

  getMenu(userId: number, id: number): Promise<INavigationItem[]> {
    return new Promise<INavigationItem[]>((resolve) => {
      let menuItems = [];
      let formData = this.app.buildForm(['userId', 'id'], [userId.toString(), id.toString()]);
      this.menuService.getMenu(formData).subscribe({
        next: (data: INavigationItem[]) => {
          if (data) {
            menuItems = data;
            menuItems.forEach(menu => {
              menu.expanded = false;
              menu.selected = false;              

              if(menu.imageIcon && menu.imageIcon.charAt(0) == 'i') {
                menu.imageIcon = this.app.domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64, ${menu.imageIcon}`);
              } else if(menu.imageIcon && menu.imageIcon.charAt(0) == '/') {
                menu.imageIcon = this.app.domSanitizer.bypassSecurityTrustUrl(`data:image/jpeg;base64, ${menu.imageIcon}`);
              } else menu.imageIcon = '';
            });
          }
          resolve(menuItems);
        }, error: (errorLog) => {
          console.log(errorLog);
        }
      });
    });
  }

  getView(item: INavigationItem): void {
    this.items.forEach(item => {
      item.selected = false;
      item.expanded = false;
    })

    item.selected = true;
    item.expanded = !item.expanded;    
    if (item.viewType == 'MasterDetailView') {
      sessionStorage.setItem('nodeId', item.id.toString());
      sessionStorage.setItem('nodeName', item.name);
      this.app.showLoader();
      this.communicationService.loadGridView();
    }

    if (item.viewType == 'GanttView') {
      sessionStorage.setItem('nodeId', item.id.toString());
      sessionStorage.setItem('nodeName', item.name);
      this.app.showLoader();
      this.communicationService.loadGanttView();
    }

    if (item.viewType == 'MapView') {
      sessionStorage.setItem('nodeId', item.id.toString());
      sessionStorage.setItem('nodeName', item.name);
      this.app.showLoader();
      this.communicationService.loadMapView();
    }
  }
}