export class ITranslationsClass {
  GBL_lbl_SelectOption: string = 'Select';
  GBL_btn_ConfirmText: string = 'Confirm';
  GBL_btn_CancelText: string = 'Cancel';
  GBL_btn_ColumnsText: string = 'Columns';
  GBL_btn_FilterText: string = 'Filter';
  GBL_btn_OkText: string = 'Ok';
  GBL_btn_SaveText: string = 'Save';
  GBL_btn_Back: string = 'Back';
  GBL_lbl_ActionsText: string = 'Actions';
  GBL_lbl_ReportText: string = 'Reports';
  GBL_lbl_DashboardsText: string = 'Dashboards';
  GBL_lbl_ReportDsgnText: string = 'Report designer';
  GBL_lbl_DashboardDsgnText: string = 'Dashboard designer';
  GBL_lbl_SettingsText: string = 'Settings';
  GBL_lbl_LogoutText: string = 'Logout';
  GBL_hint_ColSortText: string = 'Sort';
  GBL_hint_PaginateFirst: string = 'first';
  GBL_hint_PaginatePrevious: string = 'previous';
  GBL_hint_PaginateNext: string = 'next';
  GBL_hint_PaginateLast: string = 'last';
  GBL_lbl_NoRecords: string = 'No records found';
  GBL_lbl_MorningGreeting: string = 'Good morning';
  GBL_lbl_AfternoonGreeting: string = 'Good afternoon';
  GBL_lbl_EveningGreeting: string = 'Good evening';
  GBL_hint_Copy: string = 'Copy';
  GBL_lbl_ChooseRange: string = 'Choose range';
  GBL_lbl_RangeFrom: string = 'from';
  GBL_lbl_RangeTo: string = 'to';
  GBL_btn_ApplyText: string = 'Apply';
  GBL_lbl_ActionResult: string = 'Action Result';
  GBL_lbl_CookieLink: string = 'Cookie policy';
  GBL_hint_UploadImage: string = 'Upload image(.png/.jpg)';
  GBL_lbl_Of: string  = 'of';
  GBL_lbl_Rows: string = 'rows';
  GBL_lbl_Removed: string = 'removed';
  GBL_hint_UploadFile: string = 'Upload file';
  GBL_btn_PasteData: string = 'Paste Data';
  GBL_lbl_Ascending: string = 'Ascending';
  GBL_lbl_Descending: string = 'Descending';

  COL_lbl_Title: string = 'Edit Columns';
  COL_lbl_CheckAll: string = 'Check All';

  SET_SETTINGSUPDATED: string = 'Settings Updated';
  SET_btn_ChangepwText: string = 'Change password';
  SET_lbl_AccountText: string = 'Account';
  SET_lbl_MenuText: string = 'Menu';
  SET_lbl_PersonalisationText: string = 'Personalisation';
  SET_lbl_TextsizeText: string = 'Text Size';
  SET_lbl_toggleExpandMenuText: string = 'Expand menu by default';
  SET_hint_TextsizeText: string = 'Sets the text size of menu labels and table fields';//unused
  SET_lbl_SampleText: string = 'Sample Text';
  SET_lbl_Sample: string = 'The quick brown fox jumps over the fence';
  SET_lbl_SampleTable: string = 'Sample Table';
  SET_lbl_SampleTableHeader: string = 'Header';
  SET_lbl_SampleTableField: string = 'Field';
  SET_lbl_CompactViewText: string = 'Compact view';
  SET_lbl_toggleCompactText: string = 'Show all grids in compact mode by default';
  
  SET_lbl_GridsText: string = 'Grids';
  SET_lbl_DarkModeText: string = 'Dark Mode';
  SET_lbl_DarkModeSettingText: string = 'Enable dark mode';
  SET_lbl_DarkModeMatchSettingText: string = 'Match system settings';
  SET_lbl_TextsizeSettingText: string = 'Change the text size of grids and menus';
  SET_lbl_FilterSettingText: string = 'Apply filters on detail views by row';
  SET_btn_ChangePasswordText: string = 'Change';
  SET_btn_ChangePasswordSettingText: string = 'your password';
  SET_lbl_FilterSettingTextTitle: string = 'Filter';
  SET_lbl_ErrorInfoText: string = 'Error Information';
  SET_lbl_ErrorInfo: string = 'See detailed error page every time an error occurs';

  COOKIEBANNER_Desc: string = 'By using this portal you consent to using cookies';
  COOKIEBANNER_Lnk: string = 'more info';

  CMD_lbl_Title: string = 'Please confirm your changes';
  CMD_lbl_Field: string = 'field';
  CMD_lbl_NewValue: string = 'new value';
  CMD_lbl_UpdateWarning: string = 'lines will be updated';

  CON_lbl_DefaultPrompt1: string = 'Are you sure you want to execute the';
  CON_lbl_DefaultPrompt2: string = 'action';

  NOT_lbl_Title: string = 'Notifications';
  NOT_lbl_NoNotifications: string = 'no notifications';
  NOT_lbl_Empty: string = 'all empty for now';
  NOT_lbl_ReleaseNotes: string = 'Release notes';
  NOT_lbl_Information: string = 'Information';

  ERR_DirectionP1: string = 'Please contact support at';
  ERR_DirectionP2: string = 'and provide them with this reference number';
  ERR_Generic: string = 'An error occurred';
  ERR_404: string = 'It seems the page you\'re trying to load a page that doesn\'t exist';
  ERR_ErrorMessage: string = 'Error Message';
  ERR_ErrorData: string = 'Error Data';

  GRID_btn_Add: string = 'Add';
  GRID_hint_Add: string = 'Add rows to insert multiple entries';
  GRID_lbl_EditLineCount: string = 'Editing lines(s)';
  GRID_lbl_ExecuteSuccess: string = 'Success';
  GRID_hint_RemoveRow: string = 'Remove row';

  ALERT_Error_SelectNoLines: string = 'Please select a line';
  ALERT_Error_OnlySelectOneLine: string = 'Please select only 1 line';
  ALERT_Error_TryAgain: string = 'Please try again';
  ALERT_Error_InvalidImage: string = 'Image format not supported';
  ALERT_Error_CloseWithoutSave: string = 'Please save changes before closing';
  ALERT_Info_NothingToSave: string = 'Nothing to save';
  ALERT_Info_Copied: string = 'Text copied';
  ALERT_Info_NoRecordsFromFilters: string = 'No records matching filter';
  ALERT_Success_Success: string = 'Success';
  ALERT_Success_Updated: string = 'Updated';
  ALERT_Success_Executed: string = 'Executed';
  ALERT_Error_UsernameRequired: string = 'Please enter username';
  ALERT_Error_PasswordRequired: string = 'Please enter password';
  ALERT_Error_CurrentPasswordRequired: string = 'Please enter current password';
  ALERT_Error_NewPasswordRequired: string = 'Please enter a new password';
  ALERT_Error_ConfirmPassword: string = 'Please confirm password';
  ALERT_Error_PasswordMisMatch: string = 'Passwords do not match';
  ALERT_Error_WeakPassword: string = 'Password too weak';
  ALERT_Info_PasswordExpired: string = 'Password expired';
  ALERT_Error_WrongCredentials: string = 'Incorrect username or password';
  ALERT_Error_InvalidValues: string = 'Some values are invalid';
  ALERT_Error_InvalidDecimal: string = 'Decimals not allowed for';
  ALERT_Error_RemoveAllRows: string = 'Cannot remove all rows';
  ALERT_Error_AllHeadersHidden: string = 'At least 1 column must be checked';
  ALERT_Error_InvalidDateRange: string = 'Please choose a start & end date/time';
  ALERT_Info_ImageRemoved: string = 'Image removed';
  ALERT_Error_NoModuleSelected: string = 'Please select a module';
  ALERT_Error_NoFileUploaded: string = 'Please upload a file to import';
  ALERT_Error_InvalidExcelData: string = 'Invalid Excel data';
  ALERT_Error_ViewInaccessible: string = 'View not accessible';
  ALERT_Error_InvalidToken: string = 'Invalid token, please try again';
  ALERT_Error_ErrorWithRefNumberOccured: string = 'An error occurred. See error page. Ref';
  ALERT_Error_ImageCannotProcess: string = 'Image could not be processed';

  LGN_btn_LoginText: string = 'Login';
  LGN_lbl_ForgotpwText: string = 'Forgot Password';
  LGN_lbl_ResetPassword: string = 'Reset Password';
  LGN_btn_ResetText: string = 'Reset';
  LGN_hint_Back: string = 'Back';
  LGN_hint_NewPassword: string = 'Password must be at least: 8 characters long and contain at least 1 lowercase letter, 1 uppercase letter and a number';
  LGN_place_NewPassword: string = 'new password';
  LGN_place_ConfirmPassword: string = 'confirm password';
  LGN_place_CurrentPassword: string = 'current password';
  LGN_place_Username: string = 'username';
  LGN_place_Password: string = 'password';
  LGN_lbl_Login: string = 'Login';

  IMP_lbl_Title: string = 'Import File';

} 
