import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { IconDefinition, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-report-component',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './report.component.html',
  styleUrls: [
    "./report.component.css",
    "../../../node_modules/devextreme/dist/css/dx.common.css",
    "../../../node_modules/devextreme/dist/css/dx.material.blue.light.css",
    "../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css",
    "../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.material.blue.light.css",
    "../../../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css",
    "../../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css",
    "../../../node_modules/devexpress-reporting/dist/css/dx-reportdesigner.css",    
    ]
})

export class ReportComponent {
  getDesignerModelAction: string = "DXXRD/GetDesignerModel";
  reportUrl: string;
  invokeAction: string = '/DXXRDV';
  loadedFromDirect: boolean = false;
  viewerMode: boolean = false;
  userId: string;
  faArrowLeftLong: IconDefinition = faArrowLeftLong;

  constructor(
    @Inject('BASE_URL') public hostUrl: string,
    public app: AppComponent
  ) { 
    if(sessionStorage.getItem('reportId') == undefined && sessionStorage.getItem('directedReportId') == undefined) return;
    if (!this.app.cookieService.check('loggedIn')) window.location.href = 'signin';
       
    this.viewerMode = true;
    this.userId = this.app.cookieService.get('loggedIn').split('|')[1];
    
    if (sessionStorage.getItem('reportLineId') != undefined) {
      this.reportUrl = `${sessionStorage.getItem('reportId')!.toString()}?USERID=${this.userId}&ID=${sessionStorage.getItem('reportLineId')!.toString()}`;
    } else if (sessionStorage.getItem('directReportLineId') != undefined) {    
      this.loadedFromDirect = true;
      this.viewerMode = true;
      this.reportUrl = `${sessionStorage.getItem('directedReportId')!.toString()}?USERID=${this.userId}&ID=${sessionStorage.getItem('directReportLineId')!.toString()}`;
    } else if (sessionStorage.getItem('directedReportId') != undefined) {
      this.loadedFromDirect = true;
      this.viewerMode = true;
      this.reportUrl = `${sessionStorage.getItem('directedReportId')!.toString()}?USERID=${this.userId}`;
    } else if (sessionStorage.getItem('reportId') != undefined) {
      this.reportUrl = `${sessionStorage.getItem('reportId')!.toString()}?USERID=${this.userId}`;
    }
   
    sessionStorage.clear();
    sessionStorage.setItem('userId', this.userId);
  }  

  back(): void {
    sessionStorage.clear();
    sessionStorage.setItem('userId', this.userId);
    window.location.href = '/base';
  }
}