import { Component, HostListener, Input } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { CommunicationService } from 'app/_services/communication.service';
import { IconDefinition, faSquareCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'feedback-component',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent {
  @Input() darkMode: boolean; 
  @Input() feedbackMessage: string;
  @Input() fromLogin: boolean = false;
  faSquareCheck: IconDefinition = faSquareCheck;
  faXmark: IconDefinition = faXmark;
  
  constructor(
    public app: AppComponent,    
    private communicationService: CommunicationService
  ) { }

  close(): void { 
    if(!this.fromLogin) {
      this.communicationService.closeFeedbackDialog(); 
      if(!this.feedbackMessage.includes('ERROR')) {
        this.communicationService.closeDialog();
        this.app.alertSuccess(this.app.translations.ALERT_Success_Success);
        this.communicationService.refreshAll();
      }

      return;
    }
    
    this.communicationService.closeFeedbackDialogFromLogin();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Esc') this.close();
  }
}
