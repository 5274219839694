import { Component, OnInit, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { IError } from 'app/_models/IError';
import { ITranslationsClass } from '../_models/ITranslationsClass'
import { CookieService } from 'ngx-cookie-service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'error-component',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  darkMode: boolean = false;  
  errorDetails: IError;

  constructor(    
    public translations: ITranslationsClass,
    public cookieService: CookieService,
    public clipboard: ClipboardService,
    private location: Location
  ) {
    if (this.cookieService.check('darkMode')) 
      this.darkMode = this.tryParseBoolean(this.cookieService.get('darkMode'));

    if(sessionStorage.getItem('errorDetails')) {            
      this.errorDetails = JSON.parse(sessionStorage.getItem('errorDetails'));      
      sessionStorage.removeItem('errorDetails');
      console.log(this.errorDetails);            
    }
  }

  tryParseBoolean(value: any): any {
    if (typeof value === 'boolean') return value;

    if (typeof value === 'string') {
      let lowercaseValue = value.trim().toLowerCase();
      if (lowercaseValue === 'true' || lowercaseValue === '1') return true;
      if (lowercaseValue === 'false' || lowercaseValue === '0') return false;
    }

    return value;
  }

  ngOnInit() { window.addEventListener('popstate', this.handlePopState); }
  ngOnDestroy(): void { window.removeEventListener('popstate', this.handlePopState); }
  handlePopState(): void { }
  @HostListener('window:popstate', ['$event'])
  onPopState(event): void { this.location.forward(); }
  copy(text: any): void { this.clipboard.copy(text); }

  logout(): void {
    sessionStorage.clear();
    this.cookieService.delete('loggedIn');
    window.location.href = '/signin';
  }
}
