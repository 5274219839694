<p-dialog [visible]="true" [breakpoints]="{'430px': '100%'}" [style]="{width: '40%'}" [baseZIndex]="10000" [modal]="true" [maximizable]="false"
  [draggable]="false" [resizable]="false">
  <div class="row sticky dialog-title-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 unset-p">
      <div class="flex relative align-centre dialog-title">
        <span class="textGreen font16 b-radius dialog-title-icon"
          [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
          <fa-icon [icon]="faSquareCheck"></fa-icon>         
        </span>
        <span class="font17" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
          Advanced Filters
        </span>
        <div class="absolute" style="right: 15px;">
          <span class="textGreen font16 b-radius dialog-title-icon cursorPointer" (click)="close()"
            [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
            <fa-icon [icon]="faXmark"></fa-icon>           
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 animated fadeIn padding15">      
      <textarea [innerText]="filterString" class="form-control"></textarea>
    </div>
  </div>
</p-dialog>
