import { Component, Input, OnInit } from '@angular/core';
import { CommunicationService } from 'app/_services/communication.service';
import { AppComponent } from 'app/app.component';
import { 
  IconDefinition, faGear, faBell, faArrowUpRightFromSquare, 
  faGauge, faArrowUpFromBracket, faRightFromBracket 
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'user-menu-component',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent implements OnInit {  
  @Input() darkMode: boolean;
  @Input() username: string;
  @Input() userRole: string;
  @Input() greeting: string;
  @Input() mobileView: boolean = false;
  //#region Icons
  faGear: IconDefinition = faGear;
  faBell: IconDefinition = faBell;
  faArrowUpRightFromSquare: IconDefinition = faArrowUpRightFromSquare;
  faGauge: IconDefinition = faGauge;
  faArrowUpFromBracket: IconDefinition = faArrowUpFromBracket;
  faRightFromBracket: IconDefinition = faRightFromBracket;
  //#endregion

  constructor(
    public app: AppComponent,
    private communicationService: CommunicationService
  ) { }

  ngOnInit() { }

  openSettings(): void {
    this.communicationService.closeUserMenu(false);
    this.communicationService.showSettingsDialog();
  }

  openNotifications(): void {
    this.communicationService.closeUserMenu(false);
    this.communicationService.showNotificationsDialog();
  }

  newReport(): void {
    this.communicationService.closeUserMenu(false);
    sessionStorage.removeItem('reportId');
    window.open('report-designer', '_blank');
  }

  newDashboard(): void {
    this.communicationService.closeUserMenu(false);
    sessionStorage.removeItem('dashboardId');
    window.open('dashboard-designer', '_blank');
  }

  openFileImport(): void {
    this.communicationService.closeUserMenu(false);
    this.communicationService.showImportFileDialog();
  }

  logout(): void {
    this.communicationService.closeUserMenu(true);
  }

  openCookiePolicy(): void {
    this.communicationService.closeUserMenu(false);
    window.open('/assets/cookies.html');
  }
}