<div class="padding10">
  <span class="font15" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
    {{greeting}} {{username}}
  </span>
  <br>
</div>
<div class="cursorPointer padding10 menu-item" (click)="openSettings()">
  <fa-icon [icon]="faGear" class="font15" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"></fa-icon>  
  <span class="font15 animated fadeIn menu-item-text b-radius padding3"
    [ngClass]="darkMode ? 'textWhite menu-item-dark' : 'textBlack menu-item-light'">
    {{app.translations.GBL_lbl_SettingsText}}
  </span><br>
</div>
<div class="cursorPointer padding10 menu-item notification-user-menu" (click)="openNotifications()">
  <fa-icon [icon]="faBell" class="font15" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"></fa-icon>  
  <span class="font15 animated fadeIn menu-item-text b-radius padding3"
    [ngClass]="darkMode ? 'textWhite menu-item-dark' : 'textBlack menu-item-light'">
    {{app.translations.NOT_lbl_Title}}
  </span><br>
</div>
<ng-template [ngIf]="!mobileView && (userRole == 'Administrator' || userRole == 'Dev')">
  <div class="cursorPointer padding10 menu-item" (click)="newReport()">
    <fa-icon [icon]="faArrowUpRightFromSquare" class="font15" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"></fa-icon>    
    <span class="font15 animated fadeIn menu-item-text b-radius padding3"
      [ngClass]="darkMode ? 'textWhite menu-item-dark' : 'textBlack menu-item-light'">
      {{app.translations.GBL_lbl_ReportDsgnText}}
    </span><br>
  </div>
  <div class="cursorPointer padding10 menu-item" (click)="newDashboard()">
    <fa-icon [icon]="faGauge" class="font15" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"></fa-icon>    
    <span class="font15 animated fadeIn menu-item-text b-radius padding3"
      [ngClass]="darkMode ? 'textWhite menu-item-dark' : 'textBlack menu-item-light'">
      {{app.translations.GBL_lbl_DashboardDsgnText}}
    </span><br>
  </div>  
</ng-template>
<div class="cursorPointer padding10 menu-item" (click)="openFileImport()">
  <fa-icon [icon]="faArrowUpFromBracket" class="font15" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"></fa-icon> 
  <span class="font15 animated fadeIn menu-item-text b-radius padding3"
    [ngClass]="darkMode ? 'textWhite menu-item-dark' : 'textBlack menu-item-light'">
    {{app.translations.IMP_lbl_Title}}
  </span><br>
</div>
<div class="cursorPointer padding10 menu-item" (click)="logout()">
  <fa-icon [icon]="faRightFromBracket" class="font15" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"></fa-icon> 
  <span class="font15 animated fadeIn menu-item-text b-radius padding3"
    [ngClass]="darkMode ? 'textWhite menu-item-dark' : 'textBlack menu-item-light'">
    {{app.translations.GBL_lbl_LogoutText}}
  </span><br>
</div>
<span class="font12 animated fadeIn cursorPointer absolute padding5 cookie-policy" (click)="openCookiePolicy()"
  [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_lbl_CookieLink}}</span>
<span class="font12 animated fadeIn absolute padding5 version" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
  v4.3.13-pre
</span>