import { Injectable } from "@angular/core";
import { GridService } from 'app/_services/grid.service';
import { IHeader } from "app/_models/IHeader";
import { AppComponent } from "app/app.component";
import { ILine } from "app/_models/ILine";
import { IFilter } from "app/_models/IFilter";

@Injectable({ providedIn: 'root' })
export class GridController {
  constructor(
    private app: AppComponent,
    private gridService: GridService
  ) { }

  formatSanitise(lines: ILine[], headers: IHeader[], filters: IFilter[]): { lines: ILine[], headers: IHeader[], filters: IFilter[] } {
    headers.forEach(header => {
      if (header.dataType == 'Byte[]' && (header.displayFormat == 'string' || header.displayFormat == '' || header.displayFormat == 'IMAGE')) {
        header.displayFormat = 'IMAGE';

        lines.forEach(line => {
          if (line.list[header.caption] && line.list[header.caption].charAt(0) == 'i') {
            line.list[header.caption] = this.app.domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64, ${line.list[header.caption]}`);
          } else if (line.list[header.caption] && line.list[header.caption].charAt(0) == '/') {
            line.list[header.caption] = this.app.domSanitizer.bypassSecurityTrustUrl(`data:image/jpeg;base64, ${line.list[header.caption]}`);
          }
        })
      }

      if (header.dataType == 'HTML') header.displayFormat = 'HTML';

      filters.forEach(filter => {
        if (header.caption == filter.caption && filter.displayFormat != 'string') {
          header.displayFormat = filter.displayFormat == undefined ? '' : filter.displayFormat;
        }

        if (filter.value != '') {
          filter.isActive = true;
          header.isActive = true;
        }
      })
    })

    let lineNumber = 1;
    lines.forEach(line => {
      for (let key in line.list) {
        if (line.list[key] && (line.list[key].toString().toLowerCase() === 'true' || line.list[key].toString().toLowerCase() === 'false' || line.list[key].length == 0)) {
          line.list[key] = this.app.tryParseBoolean(line.list[key]);
          headers.forEach(header => {
            if (header.caption == key) header.displayFormat = 'CHECK';
          })
        }
      }

      line.lineNumber = lineNumber;
      lineNumber++;
    })

    return { lines, headers, filters };
  }

  saveLayout(nodeId: string, viewName: string, username: string, headers: IHeader[]): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let keys = ['nodeId', 'viewName', 'username', 'columns'];
      let values = [nodeId, viewName, username, JSON.stringify(headers)];
      let formData = this.app.buildForm(keys, values);

      this.gridService.saveGridLayout(formData).subscribe({
        error: (errorLog) => { console.log(errorLog.error); }
      });

      resolve(true);
    });
  }

  getColumnWidth(previousColumnWidths: string[], columnWidths: string[], rowHeader: any, headers: IHeader[]): { previousColumnWidths: string[], columnWidths: string[], headers: IHeader[] } {
    previousColumnWidths = [];
    if (columnWidths.length > 0) {
      previousColumnWidths = columnWidths;
      columnWidths = [];
    }

    //get entire table width from DOM & each <td> width
    //DOM only constructs and displays header.visible = true       
    let childrenWidths = [];
    if (rowHeader == undefined) {
      return { previousColumnWidths, columnWidths, headers };
    }

    if (rowHeader.elementRef == undefined && rowHeader.nativeElement != undefined) {
      childrenWidths = rowHeader.nativeElement.parentNode.children;
    } else {
      childrenWidths = rowHeader.elementRef.nativeElement.parentNode.children;
    }

    let rowWidth = 100;
    if (rowHeader.elementRef == undefined && rowHeader.nativeElement != undefined) {
      rowWidth = rowHeader.nativeElement.parentNode.scrollWidth;
    } else {
      rowWidth = rowHeader.elementRef.nativeElement.parentNode.scrollWidth;
    }

    let count = 1;
    let visibleCols = 0;

    for (var i = 0; i < headers.length; i++) {
      if (headers[i].visible) {
        visibleCols++;
      }
    }

    if (childrenWidths.length == visibleCols) count = 0;

    //get all col widths where header.visible = true
    //start at [1] because [0] is column with arrow 
    for (var i = count; i < childrenWidths.length; i++) {
      let scrollWidth = childrenWidths[i].scrollWidth;
      columnWidths.push((scrollWidth * 100 / rowWidth).toFixed(0));
    }

    //update visible headers with matching colwidth value
    //indexes always pair up because parentnode is built from headers
    //doing below for headers.visible = true ensures correct width gets set for given header
    //header.visible = false columns' widths get added to array at their specific order positions
    for (var i = 0; i < headers.length; i++) {
      if (headers[i].visible) {
        headers[i].width = `${columnWidths[i]}%`;
      } else {
        columnWidths.splice(i, 0, `${headers[i].width}`.replace('%', ''))
      }
    }

    //populate array of previous widths to trigger a change and save after return
    if (previousColumnWidths.length == 0) {
      for (var i = 0; i < headers.length; i++) {
        previousColumnWidths.push(`${headers[i].width}`.replace('%', ''));
      }
    }

    return { previousColumnWidths, columnWidths, headers };
  }
}