import { Component, HostListener, Input } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { CommunicationService } from 'app/_services/communication.service';
import { ImportService } from 'app/_services/import.service';
import { ILookupValues } from 'app/_models/ILookupValues';
import { IconDefinition, faArrowUpFromBracket, faXmark, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'import-component',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent {
  @Input() darkMode: boolean;  
  modules: ILookupValues[] = []; 
  selectedModule: string = '0';
  uploadedFileName: string = undefined;
  uploadedFile: string = undefined; 
  faArrowUpFromBracket: IconDefinition = faArrowUpFromBracket;
  faXmark: IconDefinition = faXmark;
  faCheckDouble: IconDefinition = faCheckDouble;

  constructor(
    public app: AppComponent,    
    private communicationService: CommunicationService,
    private importService: ImportService
  ) { 
    this.importService.getModules().subscribe(data => { this.modules = data; })
  }

  selectModule(event: any): void {
    this.selectedModule = event.srcElement.value.toString();  
    this.uploadedFileName = undefined;
    this.uploadedFile = undefined;    
  }

  uploadFile(event: any): void {
    if(this.selectedModule == '0') {
      this.app.alertError(this.app.translations.ALERT_Error_NoModuleSelected);
      return;
    }

    let fileToUpload = event.target.files;
    let file = fileToUpload[0] as File;      
    this.uploadedFileName = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result as string;      
      this.uploadedFile = base64String;
    };
  }

  importFile(): void {
    if(this.selectedModule == '0') {
      this.app.alertError(this.app.translations.ALERT_Error_NoModuleSelected);
      return;
    }

    if(!this.uploadedFile) {
      this.app.alertError(this.app.translations.ALERT_Error_NoFileUploaded);
      return;
    }

    this.app.showLoader();   
    let keys = ['module', 'fileName', 'fileData'];
    let values = [this.selectedModule, this.uploadedFileName, this.uploadedFile];    
    let formData = this.app.buildForm(keys, values);
    this.importService.importFile(formData).subscribe((data: { result: string }) => {
      this.app.hideLoader();      
      if (data.result == '') {        
        this.app.alertSuccess(this.app.translations.ALERT_Success_Success);             
        return;
      }

      this.communicationService.showFeedbackDialog(data.result);
    })
  }

  close(): void { this.communicationService.closeDialog(); }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Esc') this.close();
  }
}
