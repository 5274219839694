import { Component, HostListener, Input } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { CommunicationService } from 'app/_services/communication.service';
import { IconDefinition, faSquareCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { IFilter } from 'app/_models/IFilter';
import { IHeader } from 'app/_models/IHeader';

@Component({
  selector: 'advanced-filters-component',
  templateUrl: './advanced-filters.component.html',
  styleUrls: ['./advanced-filters.component.css']
})
export class AdvancedFiltersComponent {
  @Input() darkMode: boolean; 
  @Input() filters: IFilter[] = [];
  @Input() headers: IHeader[] = [];
  @Input() filterString: string;
  faSquareCheck: IconDefinition = faSquareCheck;
  faXmark: IconDefinition = faXmark;
  
  constructor(
    public app: AppComponent,    
    private communicationService: CommunicationService
  ) { }

  close(): void { 
    this.communicationService.closeDialog();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Esc') this.close();
  }
}
