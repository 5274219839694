import { Injectable } from '@angular/core';
import { IAction } from 'app/_models/IAction';
import { ICompiledLine } from 'app/_models/ICompiledLine';
import { IFilter } from 'app/_models/IFilter';
import { IGanttTask } from 'app/_models/IGanttTask';
import { IGridConfig } from 'app/_models/IGridConfig';
import { IHeader } from 'app/_models/IHeader';
import { INavigationItem } from 'app/_models/INavigationItem';
import { ISourceViewHeader } from 'app/_models/ISourceViewHeader';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root', })
export class CommunicationService {
  //menu tells base to load grid
  private menu_base_loadGridView_subject = new Subject<string>();
  menu_base_loadGridView$ = this.menu_base_loadGridView_subject.asObservable();
  loadGridView(): void {
    this.menu_base_loadGridView_subject.next('');
  }

  //base loads grid
  private base_grid_loadGrid_subject = new Subject<string>();
  base_grid_loadGrid$ = this.base_grid_loadGrid_subject.asObservable();
  loadGrid(): void {
    this.base_grid_loadGrid_subject.next('');
  }

  //menu tells base to load gantt
  private menu_base_loadGanttView_subject = new Subject<string>();
  menu_base_loadGanttView$ = this.menu_base_loadGanttView_subject.asObservable();
  loadGanttView(): void {
    this.menu_base_loadGanttView_subject.next('');
  }

  //base loads gantt
  private base_gantt_loadGantt_subject = new Subject<string>();
  base_gantt_loadGantt$ = this.base_gantt_loadGantt_subject.asObservable();
  loadGantt(): void {
    this.base_gantt_loadGantt_subject.next('');
  }

  //grid or gantt or map tells rad to load rad buttons using data provided
  private grid_or_gantt_rad_loadRad_subject = new Subject<{ headers: IHeader[] | ISourceViewHeader[], viewName: string }>();
  grid_or_gantt_rad_loadRad$ = this.grid_or_gantt_rad_loadRad_subject.asObservable();
  loadRadButtons<T extends IHeader[] | ISourceViewHeader[]>(headers: T, viewName: string): void {
    this.grid_or_gantt_rad_loadRad_subject.next({ headers, viewName });
  }

  //rad tells base to load form or grid action with provided data from a grid, gantt or map
  private rad_base_showActionDialog_subject = new Subject<{ headers: IHeader[] | ISourceViewHeader[], action: IAction, compiledItems: ICompiledLine[] | IGanttTask[] }>();
  rad_base_showActionDialog$ = this.rad_base_showActionDialog_subject.asObservable();
  showActionDialog<T extends IHeader[] | ISourceViewHeader[], U extends ICompiledLine[] | IGanttTask[]>(headers: T, action: IAction, compiledItems: U): void {
    this.rad_base_showActionDialog_subject.next({ headers, action, compiledItems });
  }

  //dialog-based components tells base to close the related dialog
  private action_base_closeDialog_subject = new Subject<string>();
  action_base_closeDialog$ = this.action_base_closeDialog_subject.asObservable();
  closeDialog(): void {
    this.action_base_closeDialog_subject.next('');
  }

  //updates headers for actions depending on whether main or detail view is active for both grids and gantt
  private gantt_or_detailgrid__rad_updateHeaders_subject = new Subject<IHeader[] | ISourceViewHeader[]>();
  gantt_or_detailgrid_rad_updateHeaders$ = this.gantt_or_detailgrid__rad_updateHeaders_subject.asObservable();
  updateRadHeaders<T extends IHeader[] | ISourceViewHeader[]>(headers: T): void {
    this.gantt_or_detailgrid__rad_updateHeaders_subject.next(headers);
  }

  //form, grid or sql actions tell the base component to reload grid or gantt after the action executed
  private action_base_refresh_subject = new Subject<string>();
  action_base_refresh$ = this.action_base_refresh_subject.asObservable();
  refreshAll(): void {
    this.action_base_refresh_subject.next('');
  }

  //detailgrid tells grid to deselect all its lines, if any, when a detail line is selected
  private detailgrid_grid_deselectParentLines_subject = new Subject<string>();
  detailgrid_grid_deselectParentLines$ = this.detailgrid_grid_deselectParentLines_subject.asObservable();
  deselectParentLines(): void {
    this.detailgrid_grid_deselectParentLines_subject.next('');
  }

  //open feedback dialog after action executed
  private action_base_showFeedbackDialog_subject = new Subject<string>();
  action_base_showFeedbackDialog$ = this.action_base_showFeedbackDialog_subject.asObservable();
  showFeedbackDialog(feedbackMessage: string): void {
    this.action_base_showFeedbackDialog_subject.next(feedbackMessage);
  }

  //closes feedback dialog from base comp
  private feedback_base_closeFeedbackDialog_subject = new Subject<string>();
  feedback_base_closeFeedbackDialog$ = this.feedback_base_closeFeedbackDialog_subject.asObservable();
  closeFeedbackDialog(): void {
    this.feedback_base_closeFeedbackDialog_subject.next('');
  }

  //grid or detail grids tell base to display image dialog with provided image string
  private grid_or_detailgrid_base_showImageDialog_subject = new Subject<string>();
  grid_or_detailgrid_base_showImageDialog$ = this.grid_or_detailgrid_base_showImageDialog_subject.asObservable();
  showImageDialog(image: string): void {
    this.grid_or_detailgrid_base_showImageDialog_subject.next(image);
  }

  //user menu tells base to open settings dialog
  private usermenu_base_showSettingsDialog_subject = new Subject<string>();
  usermenu_base_showSettingsDialog$ = this.usermenu_base_showSettingsDialog_subject.asObservable();
  showSettingsDialog(): void {
    this.usermenu_base_showSettingsDialog_subject.next('');
  }

  //grid or detail grids tell base to open column dialog with provided data
  private grid_or_detailgrid_base_showColumnDialog_subject = new Subject<{ headers: IHeader[], viewName: string }>();
  grid_or_detailgrid_base_showColumnDialog$ = this.grid_or_detailgrid_base_showColumnDialog_subject.asObservable();
  showColumnDialog(headers: IHeader[], viewName: string): void {
    this.grid_or_detailgrid_base_showColumnDialog_subject.next({ headers, viewName });
  }

  //column tells base to close column dialog
  private column_base_closeColumnDialog_subject = new Subject<string>();
  column_base_closeColumnDialog$ = this.column_base_closeColumnDialog_subject.asObservable();
  closeColumnDialog(): void {
    this.column_base_closeColumnDialog_subject.next('');
  }

  //base tells rad to collapse its tabs
  private base_rad_collapseRadTabs_subject = new Subject<string>();
  base_rad_collapseRadTabs$ = this.base_rad_collapseRadTabs_subject.asObservable();
  collapseRadTabs(): void {
    this.base_rad_collapseRadTabs_subject.next('');
  }

  //grids or gantt tells rad to filter buttons with provided data
  private grids_or_gantt_rad_filterRadButtons_subject = new Subject<{ currentview: string, compiledItems: ICompiledLine[] | IGanttTask[] }>();
  grids_or_gantt_rad_filterRadButtons$ = this.grids_or_gantt_rad_filterRadButtons_subject.asObservable();
  filterRadButtons<T extends ICompiledLine[] | IGanttTask[]>(currentview: string, compiledItems: T): void {
    this.grids_or_gantt_rad_filterRadButtons_subject.next({ currentview, compiledItems });
  }

  //app tells base translations finished loading
  private app_base_or_login_translationsLoaded_subject = new Subject<string>();
  app_base_or_login_translationsLoaded$ = this.app_base_or_login_translationsLoaded_subject.asObservable();
  translationLoaded(): void {
    this.app_base_or_login_translationsLoaded_subject.next('');
  }

  //user menu tells base to close the user menu and to log out if needed
  private usermenu_base_closeUserMenu_subject = new Subject<boolean>();
  usermenu_base_closeUserMenu$ = this.usermenu_base_closeUserMenu_subject.asObservable();
  closeUserMenu(loggingOut: boolean): void {
    this.usermenu_base_closeUserMenu_subject.next(loggingOut);
  }

  //detail grid tells grid to unpin or pin all child grids
  private detailgrid_grid_updateChildPinState_subject = new Subject<IGridConfig>();
  detailgrid_grid_updateChildPinState$ = this.detailgrid_grid_updateChildPinState_subject.asObservable();
  updateChildPinState(tab: IGridConfig): void {
    this.detailgrid_grid_updateChildPinState_subject.next(tab);
  }

  //user menu tells base to open import file dialog
  private usermenu_base_showImportFileDialog_subject = new Subject<string>();
  usermenu_base_showImportFileDialog$ = this.usermenu_base_showImportFileDialog_subject.asObservable();
  showImportFileDialog(): void {
    this.usermenu_base_showImportFileDialog_subject.next('');
  }  

  //user menu tells base to open notifications dialog for mobile views
  private usermenu_base_showNotificationsDialog_subject = new Subject<string>();
  usermenu_base_showNotificationsDialog$ = this.usermenu_base_showNotificationsDialog_subject.asObservable();
  showNotificationsDialog(): void {
    this.usermenu_base_showNotificationsDialog_subject.next('');
  }  

  //detail grid to zoom in and tell gc to hide its own data so detail grid can be presented as the main grid
  private detail_grid_grid_zoomIntoDetailView_subject = new Subject<string>();
  detail_grid_grid_zoomIntoDetailView$ = this.detail_grid_grid_zoomIntoDetailView_subject.asObservable();
  zoomIntoDetailView(): void {
    this.detail_grid_grid_zoomIntoDetailView_subject.next('');
  }

  //closes feedback dialog from login comp
  private feedback_login_closeFeedbackDialog_subject = new Subject<string>();
  feedback_login_closeFeedbackDialog$ = this.feedback_login_closeFeedbackDialog_subject.asObservable();
  closeFeedbackDialogFromLogin(): void {
    this.feedback_login_closeFeedbackDialog_subject.next('');
  }

  //menu tells base to load map view
  private menu_base_loadMapView_subject = new Subject<string>();
  menu_base_loadMapView$ = this.menu_base_loadMapView_subject.asObservable();
  loadMapView(): void {
    this.menu_base_loadMapView_subject.next('');
  } 

  //base loads map
  private base_map_loadMap_subject = new Subject<string>();
  base_map_loadMap$ = this.base_map_loadMap_subject.asObservable();
  loadMap(): void {
    this.base_map_loadMap_subject.next('');
  }

  //allows app module to display alerts
  private mod_app_showError_subject = new Subject<string>();
  mod_app_showError$ = this.mod_app_showError_subject.asObservable();
  showErrorAlert(refNumber: string): void {
    this.mod_app_showError_subject.next(refNumber);
  }

  //grid or detail grids tell base to display advanced dialog with provided filters and headers
  private grid_or_detailgrid_base_showAdvancedFilterDialog_subject = new Subject<{filters: IFilter[], headers: IHeader[], filterString: string}>();
  grid_or_detailgrid_base_advancedFilterDialog$ = this.grid_or_detailgrid_base_showAdvancedFilterDialog_subject.asObservable();
  showAdvancedFilterDialog(filters: IFilter[], headers: IHeader[], filterString: string): void {
    this.grid_or_detailgrid_base_showAdvancedFilterDialog_subject.next({filters, headers, filterString});
  }
}
