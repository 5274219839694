<div class="box-shadow"
  [ngClass]="[darkMode ? 'bg-dark-secondary' : 'bg-light-blue', zoomedDetailView ? 'unset-p' : 'padding5']"
  [class.disable-selection]="shiftSelect || controlSelect">
  <div class="b-radius padding5" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <mat-tab-group class="detail-tab-group" [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']"
      [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="changeTab($event)" [@.disabled]="true">
      <ng-container *ngFor="let child of children">
        <mat-tab class="relative animated fadeIn">
          <ng-template mat-tab-label>
            <div (mouseover)="showPin(child)" (mouseleave)="hidePin(child)">
              <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'"
                style="padding: 10px 30px;">{{child.caption}}</span>
              <ng-template [ngIf]="child.showPin">
                <fa-icon [icon]="faThumbTack" class="animated fadeIn cursorPointer absolute" style="right: 10px;"
                  [ngClass]="{'textGreen': child.pinned, 'textWhite': darkMode && !child.pinned, 
                            'textBlack': !darkMode && !child.pinned}" (click)="toggleTabs(child)"></fa-icon>
              </ng-template>
            </div>
          </ng-template>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
    <div class="row" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
      <div class="padding5 b-radius control-bar relative" [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
        <div class="col-12 flex unset-p">
          <button class="btn font17" (click)="toggleFilters();">
            <fa-icon [icon]="faFilter" class="padding3 b-radius textGreen control-bar-btn-icon"></fa-icon>
            <span class="control-bar-btn-text"
              [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_FilterText}}</span>
          </button>
          <button class="btn font17" (click)="openColumnDialog()">
            <fa-icon [icon]="faTableColumns" class="padding3 b-radius textGreen control-bar-btn-icon"></fa-icon>
            <span class="control-bar-btn-text"
              [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_ColumnsText}}</span>
          </button>
          <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'"
            class="font14 animated fadeIn padding10 justify-centre align-centre b-radius record-count">
            {{lines.length}} {{app.translations.GBL_lbl_Rows}}
          </span>
          <button class="btn font17 textGreen absolute" style="right: 0" (click)="toggleZoomDetailView()"
            [ngClass]="parentGridExpandedLine ? 'display-none': ''">
            <fa-icon [icon]="faMaximize"></fa-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template [ngIf]="headers.length > 0">
    <div class="relative" style="padding: 5px 0px 0px;">
      <p-table #dt class="animated fadeIn" (mouseover)="getColWidth()" [value]="lines" [columns]="headers"
        [reorderableColumns]="true" styleClass="p-datatable-gridlines" [resizableColumns]="true"
        responsiveLayout="scroll">
        <ng-template pTemplate="header" let-columns>
          <tr (mouseover)="getViewName()" class="bg-dark-tertiary">
            <ng-template [ngIf]="hasChildren">
              <th class="arrow-column" pResizableColumn [ngClass]="[darkMode ? 'bg-transparent' : 'bg-white']">
                <button type="button" class="btn font14 unset-p" (click)="collapseAll()">
                  <fa-icon [icon]="faAngleDoubleUp" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"></fa-icon>                        
                </button>
              </th>
            </ng-template>
            <ng-container *ngFor="let header of headers">
              <ng-container *ngFor="let filter of filters">
                <ng-template [ngIf]="header.visible && header.caption == filter.caption">
                  <th #tablerowInner (drag)="getColOrder(columns)" pResizableColumn pReorderableColumn
                    class="fit-content-max-width"
                    [ngStyle]="{'min-width': '50px', 'max-width': header.width, 'width': header.width }"
                    (dblclick)="removeSort(header.field)"
                    [ngClass]="['padding' + tablePadding, 'font' + globalFontSize, darkMode ? 'bg-transparent': 'bg-white']">
                    <span class="word-break" (click)="sort(header)" [ngClass]="{'sorted-column': colSortedBy === header.field, 
                            'active-filter': filter.isActive && !darkMode, 
                            'active-filter-dark': filter.isActive && darkMode,
                            'textGreyb9': filter.isNull, 
                            'textBlack': !filter.isNull && !darkMode, 
                            'textWhite': !filter.isNull && darkMode,
                            'cursorPointer': header.displayFormat != 'IMAGE',
                            'cursorNotAllowed': header.displayFormat == 'IMAGE'}">
                      <ng-template [ngIf]="sortString && colSortedBy == header.field">
                        <fa-icon [icon]="sortOrder ? faArrowUpLong : faArrowDownLong"
                          [ngClass]="['font' + globalFontSize, 'padding' + tablePadding]"></fa-icon>
                      </ng-template>
                      {{header.caption}}
                    </span>
                  </th>
                </ng-template>
              </ng-container>
            </ng-container>
          </tr>
          <!--#region Filters  -->
          <ng-template [ngIf]="showFilters">
            <tr class="animated fadeIn">
              <ng-template [ngIf]="hasChildren">
                <td class="arrow-column" pResizableColumn [ngClass]="[darkMode ? 'bg-dark-tertiary' : 'bg-white']"></td>
              </ng-template>
              <ng-container *ngFor="let header of headers">
                <ng-container *ngFor="let filter of filters">
                  <ng-template [ngIf]="header.visible && header.caption == filter.caption">
                    <ng-template [ngIf]="header.displayFormat == 'TIME'">
                      <td (dblclick)="clearFilter(filter)" class="relative cursorPointer"
                        [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark-tertiary' : 'bg-white']">
                        <button class="absolute bg-none unset-p unset-b range-filter-btn"
                          [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" (click)="op.toggle($event)">
                          <fa-icon [icon]="faDownLeftAndUpRightToCenter"></fa-icon>                         
                        </button>
                        <p-overlayPanel #op>
                          <ng-template pTemplate="content">
                            <div class="b-radius box-shadow" style="padding: 20px 0px 10px;"
                              [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']">
                              <div class="row">
                                <div class="col-12 unset-p">
                                  <span class="font15 padding5 b-radius"
                                    [ngClass]="[darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue']">
                                    {{app.translations.GBL_lbl_ChooseRange}}
                                  </span>
                                  <ng-template [ngIf]="filter.isNull">
                                    <span class="font14 textGreyb9 float-right">null</span>
                                  </ng-template>
                                </div>
                              </div><br />
                              <div class="row">
                                <div class="col-6">
                                  <span class="font14" [ngClass]="[darkMode ? 'textWhite' : 'textBlack']">
                                    {{app.translations.GBL_lbl_RangeFrom}}
                                  </span><br />
                                  <input class="form-control textBlack font14 b-radius input-filter" step=1 type="time"
                                    #txtFrom [value]="filter.range.length > 0 ? filter.range[0] : ''"
                                    (contextmenu)="nullifyDateFilter(filter, header, $event)" />
                                </div>
                                <div class="col-6">
                                  <span class="font14" [ngClass]="[darkMode ? 'textWhite' : 'textBlack']">
                                    {{app.translations.GBL_lbl_RangeTo}}
                                  </span><br />
                                  <input class="form-control textBlack font14 b-radius input-filter" step=1 type="time"
                                    #txtTo [value]="filter.range.length > 0 ? filter.range[1] : ''"
                                    (contextmenu)="nullifyDateFilter(filter, header, $event)" />
                                </div>
                              </div><br />
                              <div class="row">
                                <div class="col-12 flex justify-right">
                                  <button class="btn font17" (click)="createRangeFilter(filter)">
                                    <fa-icon [icon]="faCheck"
                                      class="textGreen padding3 b-radius control-bar-btn-icon"></fa-icon>                                    
                                    <span class="control-bar-btn-text"
                                      [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_ApplyText}}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </p-overlayPanel>
                      </td>
                    </ng-template>
                    <ng-template [ngIf]="header.displayFormat == 'DATE'">
                      <td (dblclick)="clearFilter(filter)" class="relative cursorPointer"
                        [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark-tertiary' : 'bg-white']">
                        <button class="absolute bg-none unset-p unset-b range-filter-btn" tooltipPosition="top"
                          [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" (click)="op.toggle($event)"
                          pTooltip="{{getDateFilterTooltip(filter)}}" [tooltipDisabled]="filter.range.length == 0">
                          <fa-icon [icon]="faDownLeftAndUpRightToCenter"></fa-icon>                          
                        </button>
                        <p-overlayPanel #op>
                          <ng-template pTemplate="content">
                            <div class="b-radius box-shadow" style="padding: 20px 0px 10px;"
                              [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']">
                              <div class="row">
                                <div class="col-12">
                                  <span class="font15 padding5 b-radius"
                                    [ngClass]="[darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue']">
                                    {{app.translations.GBL_lbl_ChooseRange}}
                                  </span>
                                  <ng-template [ngIf]="filter.isNull">
                                    <span class="font14 italic textGreyb9" style="padding-left: 5px;">(null)</span>
                                  </ng-template>
                                </div>
                              </div><br />
                              <div class="row">
                                <div class="col-6">
                                  <span class="p-float-label">
                                    <p-calendar [(ngModel)]="filter.range[0]" [showTime]="false" [showSeconds]="true"
                                      [showButtonBar]="true" [showIcon]="true" [dateFormat]="checkDateFormat()"
                                      class="font14" inputId="from_date"
                                      (contextmenu)="nullifyDateFilter(filter, header, $event)"></p-calendar>
                                    <label for="from_date"
                                      class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeFrom}}</label>
                                  </span>
                                </div>
                                <div class="col-6">
                                  <span class="p-float-label">
                                    <p-calendar [(ngModel)]="filter.range[1]" [showTime]="false" [showSeconds]="true"
                                      [showButtonBar]="true" [showIcon]="true" [dateFormat]="checkDateFormat()"
                                      class="font14" inputId="to_date"
                                      (contextmenu)="nullifyDateFilter(filter, header, $event)"></p-calendar>
                                    <label for="to_date"
                                      class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeTo}}</label>
                                  </span>
                                </div>
                              </div><br />
                              <div class="row">
                                <div class="col-12 flex justify-right">
                                  <button class="btn font17" (click)="createRangeFilter(filter)">
                                    <fa-icon [icon]="faCheck"
                                      class="textGreen padding3 b-radius control-bar-btn-icon"></fa-icon>                                   
                                    <span class="control-bar-btn-text"
                                      [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_ApplyText}}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </p-overlayPanel>
                      </td>
                    </ng-template>
                    <ng-template [ngIf]="header.displayFormat == 'DATETIME'">
                      <td (dblclick)="clearFilter(filter)" class="relative cursorPointer"
                        [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark-tertiary' : 'bg-white']">
                        <button class="absolute bg-none unset-p unset-b range-filter-btn" tooltipPosition="top"
                          [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" (click)="op.toggle($event)"
                          pTooltip="{{getDateTimeFilterTooltip(filter)}}" [tooltipDisabled]="filter.range.length == 0">
                          <fa-icon [icon]="faDownLeftAndUpRightToCenter"></fa-icon>                         
                        </button>
                        <p-overlayPanel #op>
                          <ng-template pTemplate="content">
                            <div class="b-radius box-shadow" style="padding: 20px 0px 10px;"
                              [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']">
                              <div class="row">
                                <div class="col-12">
                                  <span class="font15 padding5 b-radius"
                                    [ngClass]="[darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue']">
                                    {{app.translations.GBL_lbl_ChooseRange}}
                                  </span>
                                  <ng-template [ngIf]="filter.isNull">
                                    <span class="font13 textGreyb9 float-right">null</span>
                                  </ng-template>
                                </div>
                              </div><br />
                              <div class="row">
                                <div class="col-6">
                                  <span class="p-float-label">
                                    <p-calendar [(ngModel)]="filter.range[0]" [showTime]="true" [showSeconds]="true"
                                      [showButtonBar]="true" [showIcon]="true" [dateFormat]="checkDateFormat()"
                                      class="font14" inputId="from_date"
                                      (contextmenu)="nullifyDateFilter(filter, header, $event)"></p-calendar>
                                    <label for="from_date"
                                      class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeFrom}}</label>
                                  </span>
                                </div>
                                <div class="col-6">
                                  <span class="p-float-label">
                                    <p-calendar [(ngModel)]="filter.range[1]" [showTime]="true" [showSeconds]="true"
                                      [showButtonBar]="true" [showIcon]="true" [dateFormat]="checkDateFormat()"
                                      class="font14" inputId="to_date"
                                      (contextmenu)="nullifyDateFilter(filter, header, $event)"></p-calendar>
                                    <label for="to_date"
                                      class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeTo}}</label>
                                  </span>
                                </div>
                              </div><br />
                              <div class="row">
                                <div class="col-12 flex justify-right">
                                  <button class="btn font17" (click)="createRangeFilter(filter)">
                                    <fa-icon [icon]="faCheck"
                                      class="textGreen padding3 b-radius control-bar-btn-icon"></fa-icon>                                    
                                    <span class="control-bar-btn-text"
                                      [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_ApplyText}}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </p-overlayPanel>
                      </td>
                    </ng-template>
                    <ng-template [ngIf]="header.displayFormat == 'CHECK'">
                      <td (dblclick)="clearFilter(filter)"
                        [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark-tertiary' : 'bg-white']">
                        <mat-checkbox #chk [checked]="preSelectCheckBoxFilter(header, filter.field, filter.value)"
                          [indeterminate]="filter.isNull"
                          (change)="updateValue(chk.value, header.field, $event, filter)"
                          (contextmenu)="nullifyFilter(filter, header, $event)">
                        </mat-checkbox>
                      </td>
                    </ng-template>
                    <ng-template [ngIf]="header.displayFormat == '' || header.displayFormat == 'string' 
                      || header.displayFormat == 'IMAGE' || header.displayFormat == 'HTML'">
                      <td (dblclick)="clearFilter(filter)"
                        [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark-tertiary' : 'bg-white']">
                        <input class="form-control b-radius input-filter" type="text" #txtFilter
                          [ngClass]="['font' + globalFontSize, 'padding' + tablePadding, darkMode ? 'textWhite' : 'textBlack']"
                          placeholder="..." (keyup)="updateValue(txtFilter.value, header.field, $event, filter)"
                          [disabled]="header.displayFormat == 'IMAGE' || filter.dataType == 'Byte[]'"
                          [value]="header.field == filter.field ? filter.value : ''"
                          (contextmenu)="nullifyFilter(filter, header, $event)" />
                      </td>
                    </ng-template>
                  </ng-template>
                </ng-container>
              </ng-container>
            </tr>
          </ng-template>
          <!--#endregion -->
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <!--#region Rows -->
          <tr (mouseover)="getViewName()" class="bg-dark-tertiary">
            <ng-template [ngIf]="rowData.children.length > 0">
              <td class="transition4 font14 unset-white-space"
                [ngClass]="['padding' + tablePadding,
                darkMode ? (rowData.selected ? 'bg-green' : 'bg-transparent') : (rowData.selected ? 'bg-green' : 'bg-white')]">
                <button type="button" (click)="expand(rowData);"
                  [ngStyle]="{'display': rowData.children.length > 0 ? 'contents' : 'none' }">
                  <fa-icon [icon]="rowData.expanded ? faChevronDown : faChevronRight" class="transition4"                    
                    [ngClass]="[darkMode ? (rowData.selected ? 'textWhite' : 'textWhite') : (!rowData.selected ? 'textBlack' : 'textWhite')]"></fa-icon>
                </button>
              </td>
            </ng-template>
            <ng-container *ngFor="let col of columns">
              <ng-template [ngIf]="col.visible">
                <td class="cursorPointer transition4" (click)="compileLines(rowData)" [ngClass]="['padding' + tablePadding, showCompactGrid ? 'compact-grid' : '',
                  darkMode ? (rowData.selected ? 'textWhite bg-green' : 'textWhite bg-transparent') : 
                  (!rowData.selected ? 'textBlack bg-white' : 'textWhite bg-green')]"
                  [tooltipDisabled]="!showCompactGrid || col.displayFormat == 'IMAGE'"
                  pTooltip="{{rowData.list[col.caption]}}" tooltipPosition="top">
                  <ng-template [ngIf]="col.displayFormat == 'CHECK'">
                    <mat-checkbox [(ngModel)]="rowData.list[col.caption]" [disabled]="true"
                      [indeterminate]="checkIndeterminate(rowData.list[col.caption])">
                    </mat-checkbox>
                  </ng-template>
                  <ng-template [ngIf]="col.displayFormat == 'IMAGE' && rowData.list[col.caption]">
                    <img [src]="rowData.list[col.caption]" class="padding2 b-radius" width="30" height="30"
                      [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-white'"
                      (dblclick)="expandImage(rowData.list[col.caption])" />
                  </ng-template>
                  <ng-template [ngIf]="col.displayFormat == 'HTML' && !showCompactGrid">
                    <div [ngClass]="'font' + globalFontSize"
                      [ngStyle]="{'white-space': !showCompactGrid ? 'normal' : 'nowrap'}"
                      [innerHTML]="rowData.list[col.caption]"></div>
                  </ng-template>
                  <ng-template [ngIf]="col.displayFormat == 'HTML' && showCompactGrid">
                    <div [ngClass]="'font' + globalFontSize"
                      pTooltip="{{'<span>' + rowData.list[col.caption].replace('<br><br>', '') + '</span>'}}"
                      tooltipPosition="bottom" [escape]="false" [tooltipDisabled]="!showCompactGrid" [autoHide]="false"
                      [ngStyle]="{'white-space': !showCompactGrid ? 'normal' : 'nowrap'}">
                      <span>{{rowData.list[col.caption]}}</span>
                    </div>
                  </ng-template>
                  <ng-template [ngIf]="col.displayFormat == 'DATE'">
                    <span [ngClass]="'font' + globalFontSize"
                      [ngStyle]="{'white-space': !showCompactGrid ? 'normal' : 'nowrap'}">
                      {{rowData.list[col.caption]}}
                    </span>
                  </ng-template>
                  <ng-template [ngIf]="col.displayFormat == 'TIME'">
                    <span [ngClass]="'font' + globalFontSize"
                      [ngStyle]="{'white-space': !showCompactGrid ? 'normal' : 'nowrap'}">
                      {{rowData.list[col.caption]}}
                    </span>
                  </ng-template>
                  <ng-template [ngIf]="col.displayFormat == 'DATETIME'">
                    <span [ngClass]="'font' + globalFontSize"
                      [ngStyle]="{'white-space': !showCompactGrid ? 'normal' : 'nowrap'}">
                      {{rowData.list[col.caption]}}
                    </span>
                  </ng-template>
                  <ng-template [ngIf]="col.displayFormat == ''">
                    <span [ngClass]="'font' + globalFontSize"
                      [ngStyle]="{'white-space': !showCompactGrid ? 'normal' : 'nowrap'}">
                      {{rowData.list[col.caption]}}
                    </span>
                  </ng-template>
                </td>
              </ng-template>
            </ng-container>
          </tr>
          <ng-template [ngIf]="rowData.expanded && rowData.children.length > 0">
            <tr class="animated fadeIn">
              <td class="unset-p" [attr.colspan]="headers.length+1" [ngClass]="darkMode ? 'bg-dark-dark' : 'bg-white'">
                <app-detail-grid [children]="rowData.children" [rowId]="rowData.rowId" [userId]="userId"
                  [globalFontSize]="globalFontSize" [tablePadding]="tablePadding" [nodeId]="nodeId"
                  [username]="username" [showCompactGrid]="showCompactGrid" [parentGridExpandedLine]="rowData"
                  [darkMode]="darkMode" [parentGridCompiledLines]="compiledLines" [dateFormat]="dateFormat"
                  [detailedErrorsEnabled]="detailedErrorsEnabled"></app-detail-grid>
              </td>
            </tr>
          </ng-template>
          <!--#endregion -->
        </ng-template>
      </p-table>
      <ng-template [ngIf]="showDetailLoader">
        <div class="absolute animated fadeIn b-radius full-width detail-loader"></div>
      </ng-template>
    </div>
  </ng-template>
  <ng-template [ngIf]="showNoRecordsFoundLabel">
    <span class="font15 padding10 text-centre b-radius inline-block full-width no-records"
      [ngClass]="[darkMode ? 'textWhite' : 'textBlack', darkMode ? 'bg-dark' : 'bg-white']">
      {{app.translations.GBL_lbl_NoRecords}}
    </span>
  </ng-template>
</div>