import { Component, OnInit } from '@angular/core';
import { CommunicationService } from 'app/_services/communication.service';
import { AppComponent } from 'app/app.component';
import { NotificationsService } from 'app/_services/notifications.service';
import { UtilService } from 'app/_services/util.service';
import { SortFilterController } from 'app/_controllers/sortFilterController';
import { LoginService } from 'app/_services/login.service';
import { IAction } from 'app/_models/IAction';
import { ICompiledLine } from 'app/_models/ICompiledLine';
import { IGanttTask } from 'app/_models/IGanttTask';
import { IHeader } from 'app/_models/IHeader';
import { ISourceViewHeader } from 'app/_models/ISourceViewHeader';
import { InactivityService } from 'app/_services/inactivity.service';
import { IconDefinition, faBell, faXmark, faCircle } from '@fortawesome/free-solid-svg-icons';
import { LicensingService } from 'app/_services/licensing.service';
import { IFilter } from 'app/_models/IFilter';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit {
  //#region Fields
  darkMode: boolean = false;
  isMenuExpanded: boolean = true;
  nodeId: string;
  userId: string;
  username: string;
  userRole: string;
  greeting: string;
  translationsLoaded: boolean = false;
  globalFontSize: number = 15;
  menuItemPadding: number = 10;
  showNotifications: boolean = false;
  hasUnreadNotifications: boolean = false;
  isUserMenuCollapsed: boolean = true;
  showSettings: boolean = false;
  cookieConsent: boolean = false;
  tablePadding: number = 9;
  ganttView: boolean = false;
  gridView: boolean = false;
  mapView: boolean = false;
  action: IAction = undefined;
  compiledItems: ICompiledLine[] | IGanttTask[] = [];
  headers: IHeader[] | ISourceViewHeader[] = [];
  feedbackMessage: string = undefined;
  imageToExpand: string = undefined;
  headersToEdit: IHeader[] = undefined;
  viewName: string;
  showImportFileDialog: boolean = false;
  mobileView: boolean = false;
  detailedErrorsEnabled: boolean = false;
  showAdvancedFilters: boolean = false;
  filters: IFilter[] = [];
  filterString: string;
  //#endregion  
  //#region Icons
  faBell: IconDefinition = faBell;
  faCircle: IconDefinition = faCircle;
  faXmark: IconDefinition = faXmark;
  //#endregion

  constructor(
    public app: AppComponent,
    private communicationService: CommunicationService,
    private notificationsService: NotificationsService,
    private utilService: UtilService,
    public sortFilterController: SortFilterController,
    private loginService: LoginService,
    private inactivityService: InactivityService,
    private licensingService: LicensingService
  ) {
    if (window.innerWidth <= 430) this.mobileView = true;
    this.checkCookies();        
    this.userId = sessionStorage.getItem('userId').toString();

    this.communicationService.menu_base_loadGridView$.subscribe(() => {
      this.nodeId = sessionStorage.getItem('nodeId').toString();
      sessionStorage.removeItem('RadLoaded');
      this.ganttView = false;
      this.mapView = false;
      if (this.mobileView) this.isMenuExpanded = false;
      if (this.gridView) {
        this.communicationService.loadGrid();
        return;
      }

      this.gridView = true;
    })

    this.communicationService.menu_base_loadGanttView$.subscribe(() => {
      this.nodeId = sessionStorage.getItem('nodeId').toString();
      sessionStorage.removeItem('RadLoaded');
      this.gridView = false;
      this.mapView = false;
      if (this.ganttView) {
        this.communicationService.loadGantt();
        return;
      }

      this.ganttView = true;
    })

    this.communicationService.menu_base_loadMapView$.subscribe(() => {
      this.nodeId = sessionStorage.getItem('nodeId').toString();
      sessionStorage.removeItem('RadLoaded');
      this.ganttView = false;
      this.gridView = false;
      if (this.mobileView) this.isMenuExpanded = false;
      if (this.mapView) {
        return;
      }

      this.mapView = true;
    })

    this.communicationService.action_base_refresh$.subscribe(() => {
      sessionStorage.removeItem('RadLoaded');
      this.action = undefined;
      this.headers = [];
      this.compiledItems = [];
      if (this.gridView) {
        this.communicationService.loadGrid();
        return;
      }

      if (this.ganttView) {
        this.communicationService.loadGantt();
        return;
      }

      if (this.mapView) {
        this.communicationService.loadMap();
        return;
      }
    });

    this.communicationService.action_base_closeDialog$.subscribe(() => {
      this.action = undefined;
      this.headers = [];
      this.compiledItems = [];
      this.imageToExpand = undefined;
      this.showSettings = false;
      this.showNotifications = false;
      this.showImportFileDialog = false;
      this.showAdvancedFilters = false;
      this.getUnreadNotificationCount();
    });

    this.communicationService.rad_base_showActionDialog$.subscribe(data => {
      this.communicationService.collapseRadTabs();
      this.headers = data.headers;
      this.compiledItems = data.compiledItems;
      this.action = data.action;
    })

    this.communicationService.grid_or_detailgrid_base_showImageDialog$.subscribe(data => {
      this.communicationService.collapseRadTabs();
      this.imageToExpand = data;
    });

    this.communicationService.usermenu_base_showSettingsDialog$.subscribe(() => {
      this.communicationService.collapseRadTabs();
      this.showSettings = true;
    });

    this.communicationService.action_base_showFeedbackDialog$.subscribe(data => {
      this.communicationService.collapseRadTabs();
      this.feedbackMessage = data;
    });

    this.communicationService.feedback_base_closeFeedbackDialog$.subscribe(() => {
      this.feedbackMessage = undefined;
    });

    this.communicationService.grid_or_detailgrid_base_showColumnDialog$.subscribe(data => {
      this.communicationService.collapseRadTabs();
      this.headersToEdit = data.headers;
      this.viewName = data.viewName;
    });

    this.communicationService.column_base_closeColumnDialog$.subscribe(() => {
      this.headersToEdit = undefined;
    });

    this.communicationService.app_base_or_login_translationsLoaded$.subscribe(() => {
      this.translationsLoaded = true;
      let currentHour = new Date().getHours();
      this.greeting = this.app.translations.GBL_lbl_EveningGreeting;
      if (currentHour >= 0 && currentHour < 12)
        this.greeting = this.app.translations.GBL_lbl_MorningGreeting;
      if (currentHour >= 12 && currentHour < 18)
        this.greeting = this.app.translations.GBL_lbl_AfternoonGreeting;
    });

    this.communicationService.usermenu_base_closeUserMenu$.subscribe(loggingOut => {
      this.isUserMenuCollapsed = true;
      if (loggingOut) this.logout();
    });

    this.communicationService.usermenu_base_showImportFileDialog$.subscribe(() => {
      this.communicationService.collapseRadTabs();
      this.showImportFileDialog = true;
    });

    this.communicationService.usermenu_base_showNotificationsDialog$.subscribe(() => {
      this.communicationService.collapseRadTabs();
      this.openNotifications();
    });

    this.communicationService.grid_or_detailgrid_base_advancedFilterDialog$.subscribe(data => {
      this.communicationService.collapseRadTabs();
      this.headers = data.headers;
      this.filters = data.filters;
      this.filterString = data.filterString;
      this.showAdvancedFilters = true;
    });
  }

  ngOnInit(): void { }

  checkLoadedFromDirectLink(): void {
    let nodeIdFromUrl = sessionStorage.getItem('directedNodeId');
    if (nodeIdFromUrl) {
      let formData = this.app.buildForm(['nodeId', 'userId'], [nodeIdFromUrl, this.userId]);
      this.utilService.getViewUserAccess(formData).subscribe({
        next: (data: { result: boolean }) => {
          if (data.result) {
            sessionStorage.setItem('nodeId', nodeIdFromUrl);
            formData = this.app.buildForm(['nodeId'], [nodeIdFromUrl]);
            this.utilService.getViewType(formData).subscribe({
              next: (data: { result: string }) => {
                this.app.showLoader();
                sessionStorage.removeItem('directURL');
                setTimeout(() =>
                  data.result == 'MasterDetailView' ? this.communicationService.loadGridView() :
                    data.result == 'GanttView' ? this.communicationService.loadGanttView() :
                      this.communicationService.loadMapView()
                  , 2000);
              }, error: (errorLog) => {
                console.log(errorLog);
              }
            })
          } else {
            this.app.alertError(this.app.translations.ALERT_Error_ViewInaccessible);
            return;
          }
        }, error: (errorLog) => {
          console.log(errorLog);
        }
      })
    }
  }

  checkCookies(): void {
    if (!this.app.cookieService.check('loggedIn')) {
      window.location.href = 'signin';
      return;
    }

    this.userId = this.app.cookieService.get('loggedIn').split('|')[1];
    this.username = this.app.cookieService.get('loggedIn').split('|')[2];
    this.username = this.username.replace(this.username.charAt(0), this.username.charAt(0).toUpperCase());
    sessionStorage.setItem('userId', this.userId);
    this.getUnreadNotificationCount();
    this.checkLoadedFromDirectLink();

    let formData = this.app.buildForm(['userId', 'username'], [this.userId, this.username]);
    this.loginService.validateLoginCookie(formData).subscribe({
      next: (data: { result: boolean }) => {
        if (!data.result) {
          this.app.cookieService.delete('loggedIn');
          window.location.href = 'signin';
        } else {
          if(localStorage.getItem('sessionId')) {                       
            this.inactivityService.startSessionValidation();
          } else {            
            let stationId = localStorage.getItem('stationId');
            let formData = this.app.buildForm(['userId', 'stationId'], [this.userId, stationId]);
            this.licensingService.createSession(formData).subscribe({
              next: (data: { result: boolean, message: string, sessionId: string, stationId: string }) => {
                if (data.result) {                             
                  localStorage.setItem('sessionId', data.sessionId);  
                  localStorage.setItem('stationId', data.stationId);  
                  this.inactivityService.startSessionValidation();                              
                }
              }, error: (errorLog) => {
                console.log(errorLog);
              }
            })
          }                    
        }
      }, error: (errorLog) => {
        console.log(errorLog);
      }
    })

    this.sortFilterController.checkFilterCookies();
    if (sessionStorage.getItem('resetPassword') != undefined) {
      this.showSettings = true;
      sessionStorage.removeItem('resetPassword');
    }

    if (this.app.cookieService.check('matchSystemDarkMode')) {
      let darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      this.darkMode = darkModeMediaQuery.matches;
      this.app.cookieService.set('darkMode', this.darkMode.toString(), 365, '/', '', false);
    }

    if (this.app.cookieService.check('consented')) this.cookieConsent = true;

    if (this.app.cookieService.check('darkMode'))
      this.darkMode = this.app.tryParseBoolean(this.app.cookieService.get('darkMode'));

    if (this.app.cookieService.check('MenuToggle'))
      this.isMenuExpanded = this.app.tryParseBoolean(this.app.cookieService.get('MenuToggle'));

    if (this.app.cookieService.check('fontSize')) {
      this.globalFontSize = Number(this.app.cookieService.get('fontSize'));

      this.menuItemPadding = this.menuItemPadding - Math.floor(this.globalFontSize / 5) - 1;
      if (this.menuItemPadding < 1 || this.globalFontSize == 10) this.menuItemPadding = 2;
      if (this.globalFontSize >= 15) this.menuItemPadding = 10;

      this.tablePadding = this.tablePadding - Math.floor(this.globalFontSize / 5) - 1;
      if (this.tablePadding < 1 || this.globalFontSize == 10) this.tablePadding = 2;
      if (this.globalFontSize >= 15) this.tablePadding = 9;
    }

    if (this.app.cookieService.check('detailedErrors'))
      this.detailedErrorsEnabled = this.app.tryParseBoolean(this.app.cookieService.get('detailedErrors'));

    this.getUserRole();
  }

  consentToCookies(): void {
    this.cookieConsent = true;
    this.app.cookieService.set('consented', 'true', 365, '/', '', false);
  }

  logout(): void {
    this.inactivityService.stopMonitoring();
    this.inactivityService.terminateSession();
    sessionStorage.clear();
    this.app.cookieService.delete('loggedIn');
    window.location.href = '/signin';
  }

  getUserRole(): void {
    let formData = this.app.buildForm(['userId'], [this.userId]);
    this.utilService.getUserRole(formData).subscribe({
      next: (data: { result: string }) => {
        if (data) {
          this.userRole = data.result;
        }
      },
      error: (errorLog) => {
        console.log(errorLog);
      }
    });
  }

  openUserMenu(): void {
    this.communicationService.collapseRadTabs();
    this.translationsLoaded = true;
    this.isUserMenuCollapsed = !this.isUserMenuCollapsed;
  }

  openNotifications(): void {
    this.communicationService.collapseRadTabs();
    this.showNotifications = true;
    this.hasUnreadNotifications = false;
  }

  getUnreadNotificationCount(): void {
    let formData = this.app.buildForm(['userId'], [this.userId]);
    this.notificationsService.getUnreadCount(formData).subscribe({
      next: (data: { result: number }) => {
        if (data.result > 0) this.hasUnreadNotifications = true;
      },
      error: (errorLog) => {
        console.log(errorLog);
      }
    });
  }

  openCookiePolicy(): void {
    this.communicationService.closeUserMenu(false);
    window.open('/assets/cookies.html');
  }
}