import { Component, HostListener, Input, OnInit } from '@angular/core';
import { CommunicationService } from 'app/_services/communication.service';
import { MapService } from 'app/_services/map.service';
import { AppComponent } from 'app/app.component';
import { debounceTime, Subject } from 'rxjs';
import { ILookupValues } from 'app/_models/ILookupValues';
import { IGridConfig } from 'app/_models/IGridConfig';
import { ILine } from 'app/_models/ILine';
import { IFilter } from 'app/_models/IFilter';
import { SortFilterController } from 'app/_controllers/sortFilterController';
import { IHeader } from 'app/_models/IHeader';
import { IActiveFilter } from 'app/_models/IActiveFilter';
import { GridController } from 'app/_controllers/gridController';
import { SelectExpandController } from 'app/_controllers/selectExpandController';
import { ICompiledLine } from 'app/_models/ICompiledLine';
import { IGridData } from 'app/_models/IGridData';
import { IMarkerData } from 'app/_models/IMarkerData';
import { IMapMarker } from 'app/_models/IMapMarker';
import { IMapRoute } from 'app/_models/IMapRoute';
import { 
  IconDefinition, faTableColumns, faFilter, faArrowUpLong, faArrowDownLong, 
  faDownLeftAndUpRightToCenter, faCheck, faAngleLeft, faAngleRight, faChevronDown
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {  
  //#region Fields  
  @Input() darkMode: boolean;
  @Input() globalFontSize: number;
  @Input() tablePadding: number;
  @Input() userId: string;
  @Input() username: string;
  @Input() detailedErrorsEnabled: boolean;
  nodeId: string;
  selectedTabIndex: number = 0;
  mainData: ILookupValues[] = [];
  keyValue: string = undefined;
  extendedData: boolean = false;
  showFilters: boolean = false;
  masterConfig: IGridConfig;
  currentConfig: IGridConfig;
  headers: IHeader[] = [];
  lines: ILine[] = [];
  markerData: IMarkerData[] = [];
  mapMarkers: IMapMarker[] = [];
  mapRoutes: IMapRoute[] = [];
  map: google.maps.Map;  
  filterGridWithDelay: boolean = true;
  filterString: string = '';
  filters: IFilter[] = [];
  updatedFilterForGrid: IActiveFilter;
  compiledLines: ICompiledLine[] = [];
  sortOrder: boolean = true;
  colSortedBy: string;
  sortString: string;
  dateFormat: string = '-'; 
  sortChanged: Subject<string> = new Subject<string>();
  filterChanged: Subject<IActiveFilter> = new Subject<IActiveFilter>();
  loadedFromDirectLink: boolean = false;    
  i = 0;    
  //#endregion

  //#region Icons
  faTableColumns: IconDefinition = faTableColumns;
  faFilter: IconDefinition = faFilter;
  faArrowUpLong: IconDefinition = faArrowUpLong;
  faArrowDownLong: IconDefinition = faArrowDownLong;
  faDownLeftAndUpRightToCenter: IconDefinition = faDownLeftAndUpRightToCenter;
  faCheck: IconDefinition = faCheck;
  faAngleLeft: IconDefinition = faAngleLeft;
  faAngleRight: IconDefinition = faAngleRight;
  faChevronDown: IconDefinition = faChevronDown;
  //#endregion

  constructor(
    public app: AppComponent,
    private mapService: MapService,
    private communicationService: CommunicationService,
    public sortFilterController: SortFilterController,
    private gridController: GridController,
    private selectExpandController: SelectExpandController,
  ) {
    this.communicationService.base_map_loadMap$.subscribe(() => {
      this.getData();
    })

    this.filterChanged.pipe(debounceTime(1250)).subscribe(newFilter => {
      if (this.filterGridWithDelay) {
        this.buildFilterString(newFilter.value, newFilter.field, newFilter.isNull);
      }
    });

    this.sortChanged.pipe(debounceTime(1000)).subscribe(() => {
      this.getData();
    });
  }

  ngOnInit(): void {
    this.app.hideLoader();
    //initialise from direct link
    if (sessionStorage.getItem('directedNodeId') != undefined) {          
      this.filterString = '';
      this.loadedFromDirectLink = true;   
      this.nodeId = sessionStorage.getItem('directedNodeId');  
      sessionStorage.removeItem('directedNodeId');   
      sessionStorage.removeItem('directURL');      
      this.getMainData();
      return;
    }

    this.loadedFromDirectLink = false;    
    this.nodeId = sessionStorage.getItem('nodeId');
    this.getMainData();
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  initMap() {
    let mapOptions = { zoom: 12, center: { lat: -33.9249, lng: 18.4241 }, mapId: '4504f8b37365c3d0' };
    this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
  }

  getMainData(): void {
    let keys = ['nodeId', 'userId', 'viewName', 'keyfield', 'filterString', 'sortString', 'username'];
    let values = [this.nodeId, this.userId, 'master', '', '', '', this.username];
    let formData = this.app.buildForm(keys, values);
    this.mapService.getData(formData).subscribe({
      next: (data: { resultData: ILookupValues[], masterConfig: IGridConfig }) => {
        this.masterConfig = data.masterConfig;
        this.mainData = data.resultData;
        if (!sessionStorage.getItem('RadLoaded')) {
          this.communicationService.loadRadButtons(this.headers, this.masterConfig.childGrids[this.selectedTabIndex].caption);
        }

        let idFromUrl = sessionStorage.getItem('directLineId');
        if (idFromUrl) {          
          sessionStorage.removeItem('directLineId');           
          this.selectValue(undefined, idFromUrl);
        }
      }, error: (errorLog) => {
        this.app.hideLoader();
        if (this.detailedErrorsEnabled) {
          sessionStorage.setItem('errorDetails', JSON.stringify(errorLog.error));
          this.app.router.navigate(["error"]);
          return;
        }

        console.log(errorLog);
        this.app.alertError(`${this.app.translations.ALERT_Error_ErrorWithRefNumberOccured}: ${errorLog.error.refNumber}`);
      }
    });
  }
  
  getData(): void {
    this.initMap();
    //#region Setup
    if (this.filters.length == 0 && !this.loadedFromDirectLink) {
      this.filterString = this.sortFilterController.buildFilterStringFromCookies(this.nodeId, true, this.keyValue, this.masterConfig.childGrids[this.selectedTabIndex].caption);
    }

    this.sortString = '';
    if (!this.loadedFromDirectLink) {
      let cookies = this.app.cookieService.getAll();
      for (const cookie in cookies) {
        let child = this.masterConfig.childGrids[this.selectedTabIndex];
        if (cookie.includes('sortdetail') && cookie.split('|')[2] == child.name) {
          let cookieNodeId = cookie.split('|')[0].split('sortdetail')[1] == undefined ? 0 : cookie.split('|')[0].split('sortdetail')[1];
          if (cookies.hasOwnProperty(cookie) && cookieNodeId == this.nodeId) {
            let cookieName = cookie.split('|')[1];
            let cookieValue = cookies[cookie];
            this.colSortedBy = cookieName;
            this.sortString = cookieValue;
            this.sortOrder = this.sortString.includes('asc') ? true : false;
            break;
          }
        }
      }
    }
    //#endregion   
    let keys = ['nodeId', 'userId', 'viewName', 'keyfield', 'filterString', 'sortString', 'username'];
    let values = [this.nodeId, this.userId, this.masterConfig.childGrids[this.selectedTabIndex].caption, this.keyValue, this.filterString, this.sortString, this.username];
    let formData = this.app.buildForm(keys, values);
    this.mapService.getData(formData).subscribe({
      next: (data: IGridData) => {
        this.headers = data.headers;        
        this.lines = data.lines;
        if (this.filters.length > 0)
          this.headers = this.sortFilterController.reloadHeaderDisplayFormats(this.filters, this.headers);
        if (this.filters.length == 0) this.loadFilters();
        if (this.filterString.trim() != '' && !this.showFilters) this.toggleFilters();

        let result = this.gridController.formatSanitise(data.lines, this.headers, this.filters);
        this.headers = result.headers;
        this.lines = result.lines;
        this.filters = result.filters;

        this.populateMapFromConfig();
      }, error: (errorLog) => {
        this.app.hideLoader();
        if (this.detailedErrorsEnabled) {
          sessionStorage.setItem('errorDetails', JSON.stringify(errorLog.error));
          this.app.router.navigate(["error"]);
          return;
        }

        console.log(errorLog);
        this.app.alertError(`${this.app.translations.ALERT_Error_ErrorWithRefNumberOccured}: ${errorLog.error.refNumber}`);
      }
    });
  }

  populateMapFromConfig(): void {
    this.currentConfig = this.masterConfig.childGrids[this.selectedTabIndex];
    this.markerData = [];
    if (this.currentConfig.viewType == 'Markers') {
      this.lines.forEach(line => {                
        this.markerData.push({ 
          location: { 
            lat: Number((line.list)['TRK__LAST_LOC'].split(' ')[0]),
            lng: Number((line.list)['TRK__LAST_LOC'].split(' ')[1])
          }, 
          lastUpdated: line.list['TRK__LAST_UPDATE'], 
          name: line.list['SCN__NAME'], 
          id: line.list['SCN__ID'], 
          label: '' 
        });        
      });
      
      this.markerData.forEach(marker => { this.createMapMarker(marker) });
      return;
    }

    if (this.currentConfig.viewType == 'Routes') {
      this.i = 0;
      this.loadTrips();
    }
  }
    
  async createMapMarker(markerData: IMarkerData) {
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    let pin = new PinElement({
      background: '#FFFFFF00',
      borderColor: '#FF0000',
      glyph: markerData.name,//label text
      glyphColor: "black",
    });

    let marker = new AdvancedMarkerElement({
      map: this.map,
      position: markerData.location,
      content: pin.element,
      title: markerData.label,//tootlip text
    });

    let mapMarker: IMapMarker = {
      marker: marker,
      pin: pin,
      selected: false,
      title: markerData.label,
      id: markerData.id
    }

    marker.addListener('click', () => {
      let markerToToggle = this.mapMarkers.find(i => i.id == mapMarker.id);
      if (markerToToggle) {
        this.mapMarkers.forEach(marker => {
          if (marker.id != markerToToggle.id) {
            marker.selected = false;
            marker.pin.borderColor = '#FF0000';
          }
        })

        markerToToggle.selected = !markerToToggle.selected;
        markerToToggle.pin.borderColor = markerToToggle.selected ? '#72b92b' : '#FF0000';

        let linetoSelect = this.lines.find(line => line.rowId == markerToToggle.id);
        if (linetoSelect) {
          linetoSelect.selected = markerToToggle.selected;
          this.compiledLines = [];
          if (linetoSelect.selected) {
            this.compiledLines.push({ id: linetoSelect.rowId, lineData: linetoSelect.list, lineNumber: linetoSelect.lineNumber });
          }
          let viewName = this.masterConfig.childGrids[this.selectedTabIndex].name;
          this.communicationService.filterRadButtons(viewName, this.compiledLines);
        }

        this.lines.forEach(line => {
          if (line.rowId != linetoSelect.rowId) {
            line.selected = false;
          }
        })
      }
    });

    this.mapMarkers.push(mapMarker);
  }
      
  loadTrips(): void {
    if (this.i == this.lines.length || this.lines.length == 0) return;
    if (this.i < this.lines.length) {
      let line = this.lines[this.i];
      let routeOriginLat = Number(line.list['TRP__LOCATION_ORIGIN'].split(', ')[0]);
      let routeOriginLng = Number(line.list['TRP__LOCATION_ORIGIN'].split(', ')[1]);
      let routeDestinationLat = Number(line.list['TRP__LOCATION_DESTINATION'].split(', ')[0]);
      let routeDestinationLng = Number(line.list['TRP__LOCATION_DESTINATION'].split(', ')[1]);
      this.createMapMarkerRoutePoints(routeOriginLat, routeOriginLng, '', false);
      this.createMapMarkerRoutePoints(routeDestinationLat, routeDestinationLng, '', false);

      let keys = ['nodeId', 'userId', 'viewName', 'keyfield', 'filterString', 'sortString', 'username'];
      let values = [this.nodeId, this.userId, this.currentConfig.childGrids[0].caption, line.rowId, '', '', this.username];
      let formData = this.app.buildForm(keys, values);
      this.mapService.getData(formData).subscribe({
        next: (data: IGridData) => {          
          let waypoints = data.lines.length > 0 ? data.lines.map((stop) => {
            let latLng = stop.list['STP__LOCATION'].split(', ');
            this.createMapMarkerRoutePoints(Number(latLng[0]), Number(latLng[1]), stop.list['STP__NAME'], true);
            return {
              location: { lat: Number(latLng[0]), lng: Number(latLng[1]) },
              stopover: true
            };
          }) : [];

          let request: google.maps.DirectionsRequest = {
            origin: { lat: routeOriginLat, lng: routeOriginLng },
            destination: { lat: routeDestinationLat, lng: routeDestinationLng },
            waypoints: waypoints.length > 0 ? waypoints : undefined,
            travelMode: google.maps.TravelMode.DRIVING
          };

          let directionsService = new google.maps.DirectionsService();
          let directionsRenderer = new google.maps.DirectionsRenderer({
            suppressMarkers: true,
            suppressPolylines: true
          });

          directionsService.route(request, (result: any, status: any) => {
            if (status == google.maps.DirectionsStatus.OK) {
              directionsRenderer.setDirections(result);

              let routePolyline = new google.maps.Polyline({
                path: result.routes[0].overview_path,
                strokeColor: '#73b9ff',
                strokeOpacity: 0.8,
                strokeWeight: 6
              });

              routePolyline.setMap(this.map);
              google.maps.event.addListener(routePolyline, 'click', () => {
                let route = this.mapRoutes.find(r => r.id == line.rowId);
                if (route) {
                  this.mapRoutes.forEach(mapRoute => {
                    if (mapRoute.id != route.id) {
                      mapRoute.selected = false;
                      mapRoute.polyLine.setOptions({
                        strokeColor: mapRoute.selected ? '#72b92b' : '#73b9ff'
                      });
                    }
                  })

                  route.selected = !route.selected;
                  route.polyLine.setOptions({
                    strokeColor: route.selected ? '#72b92b' : '#73b9ff'
                  });

                  let linetoSelect = this.lines.find(line => line.rowId == route.id);
                  if (linetoSelect) {
                    this.lines.forEach(line => {
                      if (line.rowId != linetoSelect.rowId) {
                        line.selected = false;
                      }
                    })

                    linetoSelect.selected = route.selected;
                    this.compiledLines = [];
                    if (linetoSelect.selected) {
                      this.compiledLines.push({ id: linetoSelect.rowId, lineData: linetoSelect.list, lineNumber: linetoSelect.lineNumber });
                    }

                    let viewName = this.masterConfig.childGrids[this.selectedTabIndex].name;
                    this.communicationService.filterRadButtons(viewName, this.compiledLines);
                  }
                }
              });

              let mapRoute: IMapRoute = {
                polyLine: routePolyline,
                selected: false,
                id: line.rowId
              }

              this.mapRoutes.push(mapRoute);
              this.i++;
              this.loadTrips();
            }
          });
        }, error: (errorLog) => {
          this.app.hideLoader();
          if (this.detailedErrorsEnabled) {
            sessionStorage.setItem('errorDetails', JSON.stringify(errorLog.error));
            this.app.router.navigate(["error"]);
            return;
          }

          console.log(errorLog);
          this.app.alertError(`${this.app.translations.ALERT_Error_ErrorWithRefNumberOccured}: ${errorLog.error.refNumber}`);
        }
      });
    } else {
      this.i++;
      this.loadTrips();
    }
  }

  async createMapMarkerRoutePoints(lat: number, lng: number, title: string, isStop: boolean) {
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    let pin = new PinElement({
      background: '#FFFFFF00',
      borderColor: '#FF0000',
      glyph: title,//label text
      glyphColor: "black",
      scale: !isStop ? 1 : 0.8
    });

    let marker = new AdvancedMarkerElement({
      map: this.map,
      position: { lat: lat, lng: lng },
      content: pin.element,
      title: title,//tootlip text
    });
  }
  
  //#region |Sort
  sort(header: IHeader): void {
    if (header.displayFormat == 'IMAGE' || header.dataType == 'Byte[]') return;
    let child = this.masterConfig.childGrids[this.selectedTabIndex];
    if (this.colSortedBy != header.field) {
      this.sortOrder = true;
      this.app.cookieService.delete(`sortdetail${this.nodeId}|${this.colSortedBy}|${child.name}`);
    } else {
      this.sortOrder = !this.sortOrder;
    }

    this.colSortedBy = header.field;
    this.sortString = this.sortOrder ? `${header.field} asc` : `${header.field} desc`;
    this.app.cookieService.set(`sortdetail${this.nodeId}|${header.field}|${child.name}`, `${this.sortString}`, 365, '/', '', false);
    this.sortChanged.next(header.field);
  }

  removeSort(header: string): void {
    let child = this.masterConfig.childGrids[this.selectedTabIndex];
    this.app.cookieService.delete(`sortdetail${this.nodeId}|${header}|${child.name}`);
    this.colSortedBy = '';
    this.sortString = '';
    this.sortOrder = true;
    this.getData();
  }
  //#endregion

  //#region |Filter 
  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event: KeyboardEvent): void {    
    if (event.key == 'Enter' && this.updatedFilterForGrid) {
      this.filterGridWithDelay = false;
      this.buildFilterString(this.updatedFilterForGrid.value, this.updatedFilterForGrid.value.field, this.updatedFilterForGrid.value.isNull);
      this.updatedFilterForGrid = undefined;
    }
  }

  loadFilters(): void { this.filters = this.sortFilterController.loadFilters(this.headers); }

  toggleFilters(): void {
    this.showFilters = !this.showFilters;

    if (!this.showFilters) {
      this.filters = this.sortFilterController.clearAllFilters(this.filters, this.nodeId, this.masterConfig.childGrids[this.selectedTabIndex].caption, this.keyValue);
      this.filterString = '';
      this.getData();
      return;
    }

    let cookies = this.app.cookieService.getAll();
    for (let cookie in cookies) {
      let cookieNodeId = cookie.split('|')[0].split('detailfilter')[1] == undefined ? 0 : cookie.split('|')[0].split('detailfilter')[1];
      if (cookies.hasOwnProperty(cookie) && cookieNodeId == this.nodeId) {
        if (!this.app.cookieService.check('RowFilters') || this.app.cookieService.get('RowFilters') == 'false' && cookie.split('|').length == 2) {
          let result = this.sortFilterController.populateFiltersFromCookies(this.filters, this.headers, cookie, cookies);
          this.filters = result.filters;
          this.headers = result.headers;
        } else if (this.app.cookieService.get('RowFilters') == 'true' && cookie.split('|').length == 4 && this.keyValue.toString() == cookie.split('|')[2]) {
          let result = this.sortFilterController.populateFiltersFromCookies(this.filters, this.headers, cookie, cookies);
          this.filters = result.filters;
          this.headers = result.headers;
        }
      }
    }
  }

  nullifyFilter(filter: IFilter, header: IHeader, event: MouseEvent): void {
    event.preventDefault();
    if (filter.dataType == 'Byte[]') return;
    filter = this.sortFilterController.nullifyFilter(filter, header);
    this.filterChanged.next({ field: filter.field, value: filter.value, isNull: filter.isNull });
  }

  nullifyDateFilter(filter: IFilter, header: IHeader, event: MouseEvent): void {
    event.preventDefault();

    if (filter.isNull) {
      filter.isNull = false;
      filter.value = '';
      return;
    }

    filter = this.sortFilterController.nullifyFilter(filter, header);
  }

  updateValue(value: string, col: string, event: any, filter: IFilter): void {
    filter.isActive = true;
    let val = value == undefined ? event.checked : value;
    if (typeof val == 'string' && val.trim() == '') {
      filter.isNull = false;
      filter.isActive = false;
    }

    if (filter.displayFormat == 'CHECK') filter.isNull = false;
    this.filterGridWithDelay = true;
    this.updatedFilterForGrid = { field: col, value: val, isNull: false };
    this.filterChanged.next({ field: col, value: val, isNull: false });
  }

  createRangeFilter(filter: IFilter): void {
    if (filter.value.includes('null')) {
      this.filterChanged.next({ field: filter.field, value: filter.value, isNull: filter.isNull });
      return;
    }

    if (filter.range[0] == undefined || filter.range[1] == undefined) {
      this.app.alertError(this.app.translations.ALERT_Error_InvalidDateRange);
      return;
    }

    filter = this.sortFilterController.createRangeFilter(filter.range[0], filter.range[1], filter);
    this.buildFilterString(`${filter.displayFormat};${filter.range[0]}|RANGE|${filter.range[1]}`, filter.field, false);

  }

  buildFilterString(value: any, field: string, isNull: boolean): void {
    if (this.updatedFilterForGrid && !this.filterGridWithDelay) {
      this.filterString = this.sortFilterController.buildFilterString(this.updatedFilterForGrid.value, this.updatedFilterForGrid.field, this.filters, Number(this.nodeId), this.updatedFilterForGrid.isNull, true, this.keyValue, this.masterConfig.childGrids[this.selectedTabIndex].caption);
    } else {
      this.filterString = this.sortFilterController.buildFilterString(value, field, this.filters, Number(this.nodeId), isNull, true, this.keyValue, this.masterConfig.childGrids[this.selectedTabIndex].caption);
    }

    this.getData();
  }

  clearFilter(filterToClear: IFilter): void {
    if (filterToClear.dataType == 'Byte[]') return;
    if (!this.app.cookieService.check('RowFilters') || this.app.cookieService.get('RowFilters') == 'false') {
      this.app.cookieService.delete(`detailfilter${this.nodeId}|${filterToClear.field};${this.masterConfig.childGrids[this.selectedTabIndex].caption}`);
    } else {
      let cookies = this.app.cookieService.getAll();
      for (const cookie in cookies) {
        if (cookie.includes(`detailfilter${this.nodeId}|${filterToClear.field};${this.masterConfig.childGrids[this.selectedTabIndex].caption}|${this.keyValue}`)) {
          this.app.cookieService.delete(cookie);
          break;
        }
      }
    }

    this.filterString = this.sortFilterController.clearFilter(filterToClear, this.filters);
    this.getData();
  }

  preSelectCheckBoxFilter(header: IHeader, filterField: string, filterValue: string): boolean {
    return this.sortFilterController.preSelectCheckBoxFilter(header, filterField, filterValue);
  }

  getDateTimeFilterTooltip(filter: IFilter): string {
    return this.sortFilterController.getDateTimeFilterTooltip(filter, this.dateFormat);
  }

  getDateFilterTooltip(filter: IFilter): string {
    return this.sortFilterController.getDateFilterTooltip(filter, this.dateFormat);
  }

  //#endregion  

  //#region |Selection|Expansion
  compileLines(line: ILine): void {
    line.selected = !line.selected;
    let viewName = this.masterConfig.childGrids[this.selectedTabIndex].name;
    let result = this.selectExpandController.compileLines(line, false, false, this.compiledLines, this.lines, '', '', false);
    this.compiledLines = result.compiledLines;
    this.lines = result.lines;
    this.communicationService.filterRadButtons(viewName, this.compiledLines);

    if (viewName == 'Devices') {
      this.lines.forEach(line => {
        this.mapMarkers.forEach(marker => {
          if (line.rowId == marker.id) {
            marker.selected = line.selected;
            marker.pin.borderColor = marker.selected ? '#72b92b' : '#FF0000';
          }
        })
      })
    }

    if (viewName == 'Trips') {
      this.lines.forEach(line => {
        this.mapRoutes.forEach(route => {
          if (line.rowId == route.id) {
            route.selected = line.selected;
            route.polyLine.setOptions({
              strokeColor: route.selected ? '#72b92b' : '#73b9ff'
            });
          }
        })
      })
    }
  }

  extendData(): void { this.extendedData = !this.extendedData; }

  expandTile(line: ILine): void { line.expanded = !line.expanded; }
  //#endregion 

  //#region |Misc
  checkDateFormat(): string {
    return this.dateFormat.includes('-') ? 'dd-mm-yy' : 'dd/mm/yy';
  }

  openColumnDialog(): void {
    this.communicationService.showColumnDialog(this.headers, this.masterConfig.childGrids[this.selectedTabIndex].name);
  }  

  selectValue(event: any, idFromUrl: string): void {
    this.keyValue = event == undefined ? idFromUrl : event.srcElement.value.toString();        
    this.getData();
  }

  changeTab(event: any): void {
    this.filterString = '';
    this.selectedTabIndex = event.index;
    this.filters = [];
    this.showFilters = false;
    this.getData();
  }
  //#endregion  
}
