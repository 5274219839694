import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { header } from 'app/_misc/headers';
import { AppComponent } from 'app/app.component';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  constructor(
    public app: AppComponent,
    private httpClient: HttpClient 
  ) { }

  logError(formData: FormData) {        
    return this.httpClient.post('/exception/logclientsideerror', formData, { 
      headers: new header().FormHeader, observe: 'response' 
    }).pipe(map(response => { 
      return response.body; 
    }), catchError(this.handleError));
  }

  handleError(error: any) {    
    return throwError(error);
  }
}